import React, { ComponentState, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { ClientService } from "src/services/clientService";
import APP_ROUTES from "src/helper/app-routes";
import { renderErrors } from "src/helper/error-message-helper";
import NotificationManager from "react-notifications/lib/NotificationManager";

import { NichqHeader } from "./Nichq-heads";






export const DERS_Scale = ({ DERS, response, interfered, isPrint, questionId }) => {

  const { state } = useLocation();
  const selectedClientId = useSelector((state: ComponentState) => state.selectedClientId);
  const navigate = useNavigate();

  const [ders, setDers] = useState<any[]>([]);



  useEffect(() => {

    const Ders = DERS.map((item) => {
      const foundItem = response.find((i) => i.questionId === item.questionId);
      if (foundItem) {
        if (item.type === "question") {
          if (foundItem.value) {
            return { ...item, isSelected: true, value: foundItem.value };
          } else {
            return { ...item, isSelected: false, value: "" };
          }
        }
        return { ...item, ...foundItem };
      } else {
        return item;
      }
    });


    setDers(Ders);





  }, [response])

  const handleSubmit = async () => {
    await ClientService.insertClientQuestionnaire(
      selectedClientId, 0,
      [
        ...ders,
      ],
      questionId
    )
      .then((result) => {
        let questionList = result.resultData;
        navigate(APP_ROUTES.QUESTIONNAIRE);
        NotificationManager.success("Add successfully");
      })
      .catch((error) => {
        // setLoading(false);
        renderErrors(error.message);
      });
  };

  return (
    <div className="ml-3">

      <div className="mt-4 head-4">
        Difficulties in Emotion Regulation Scale (DERS)
      </div>

      <div>
        <b>Instructions:</b>

        Please press the response that is most true for you.
      </div>


      {
        ders.length > 0 &&
        <div className="row mt-4">
          <NichqHeader

            title={""}
            disabled={response && response.length > 0}
            subTitle={''}
            listItems={ders}
            setListItems={setDers}
          />
        </div>
      }

      <div>
        Gratz, K. L., & Roemer, L. (2004). Multidimensional assessment of emotion regulation and dysregulation: Development, factor structure, and initial validation of the difficulties in emotion regulation scale. Journal of psychopathology and behavioral assessment, 26(1), 41-54.
      </div>




      {state == null && !isPrint ? (
        <div className="text-start mt-3 col-12">
          <button onClick={handleSubmit} className="submitButon ">
            Submit
          </button>
        </div>
      ) : (
        ""
      )}






    </div>

  )

}



