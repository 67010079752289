import React, { ComponentState, useEffect, useState } from "react";
import Loader from "../../control-components/loader/loader";
import {
    Avatar,
    ExpansionPanel,
    ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import apiHelper from "../../helper/api-helper";
import { showError } from "../../util/utility";
import DropDownKendoRct from "../../control-components/drop-down/drop-down";
import { CommonService } from "../../services/commonService";
import { renderErrors } from "src/helper/error-message-helper";
import BillingComments from "./Add-billing-comment";
import EditBillingComments from './Add-billing-comment'
import { Tooltip } from "@progress/kendo-react-tooltip";
import DeleteBillingComment from "./delete-billing-comment";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import useModelScroll from "../../cutomHooks/model-dialouge-scroll";
import { NumericFormat } from "react-number-format";
import { DocumentBillingHistory, DocumentBillingHistoryResponse, DropDownObjectWithString } from "src/dataModels/docListModel";
import { ListView, ListViewFooter, ListViewHeader, ListViewItemProps } from "@progress/kendo-react-listview";
import { getBillingTransItemByClaimId, getDocBillinHistory, recalculateDocUnits, removeDocUnits, saveDocBillingHistory, selectBatchId, selectPayerClaimId } from "src/redux/actions";
import { useNavigate } from "react-router";
import APP_ROUTES from "src/helper/app-routes";
import { docs } from "src/services/documentService";
import ViewRejectionErrors from "../billing/transaction/viewRejectionErrors";
import { selectEOBId } from "src/redux/actions";
import { BlobOptions } from "buffer";
import ServiceLineModel from "./service-line";
import { permissionEnum, userIsSuperAdmin } from "src/helper/permission-helper";
import DocumentActionModel from "./document-action/doc-action";
import { API_ENDPOINTS } from "src/services/api-endpoints";

import trashIcon from "../../assets/images/trash2.png";
import editPencil from "../../assets/images/pencil-edit-02.png";
import CommonActionDialog from "src/control-components/custom-delete-dialog-box/common-dialog-with-buttons";
const ViewBillingStatus = ({ custAuthId, authId, documentDetail, documentId, onCloseBilling, ViewBillingStatus }) => {
    const state: ComponentState = useSelector(states => {
        return states;
    });
    const dispatch = useDispatch();
    const staffInfo = useSelector((state: ComponentState) => state.getStaffReducer);
    const rolePermisson = useSelector((state: ComponentState) => state.getRolePermission);
    const [loading, setLoading] = useState(false);
    const [billStatusData, setBillStatusData] = useState([]);
    const [expDocBilling, setExpDocBilling] = React.useState(true);
    const [isEditing, setIsEditing] = useState(false)
    const [selectedStatus, setSelectedStatus] = useState<DropDownObjectWithString>()
    const [isBillingCommentOpen, setIsBillingCommentOpen] = useState(false)
    const [billingComments, setBillingComments] = useState([])
    const [commentSelectedId, setCommentSelectedId] = useState()
    const [editBillingComment, setEditBillingComment] = useState(false)
    const [isDeleteBillingComment, setIsDeleteBillingComment] = useState(false)
    const [selectedDeleteId, setSelectedDeleteId] = useState()
    const [isDocumentAlreadyBilled, setIsDocumentAlreadyBilled] = useState()
    const [modelScroll, setScroll] = useModelScroll();
    const [billingHistory, setBillingHistory] = useState<DocumentBillingHistory[]>();
    const [amtPaid, setAmtPaid] = useState<string>('');
    const navigate = useNavigate();
    const [errorIsVisible, showClaimError] = React.useState(false);
    const [claimErrors, setClaimSelectedData] = React.useState();
    const [isOpenServiceLineModel, setIsOpenServiceLineModel] = useState<boolean>(false)
    const [claimId, setClaimId] = useState()
    const staffLoginInfo = state.getStaffReducer;
    const [isOpenDocumentActionModel, setIsOpenDocumentActionModel] = useState<boolean>(false)
    const [showRemoveDialog, setShowRemoveDialog] = useState<boolean>(false);
    useEffect(() => {
        if (documentId) {
            setBillingHistory([])
            dispatch(saveDocBillingHistory());
            getDocBillinHistory()
            fetchBillingComments()
            let obj: DropDownObjectWithString = {
                id: documentDetail?.billingStatusId,
                name: documentDetail?.billingStatusName
            }
            setSelectedStatus(obj)

        }
        fetchBillingStatus()
    }, [documentDetail, documentId])

    function handleRemoveRestoreUnits(isRemove) {
        if (isRemove) {
            setShowRemoveDialog(true)
        }
        else {
            const data = {
                documentId: documentId
            }
            dispatch(recalculateDocUnits(data))
        }
    }

    function handleYes() {
        const data = {
            documentId: documentId
        }
        dispatch(removeDocUnits(data))
        setShowRemoveDialog(false)
    }




    async function getDocBillinHistory() {
        setLoading(true);
        const response: DocumentBillingHistoryResponse = await docs.documentBillingHistory(documentId);
        setLoading(false);
        setBillingHistory(response.resultData);
    }


    async function fetchBillingStatus() {
        setLoading(true);
        return apiHelper.getRequest(API_ENDPOINTS.GET_BILLING_STATUSES)
            .then((result) => {
                if (result.resultData) {
                    setBillStatusData(result.resultData);
                }
            })
            .catch((err) => {
                showError(err, "Fetch Billing Status");
            })
            .finally(() => {
                setLoading(false);
            });
    }


    const updateBillingStatus = async (id: any) => {
        let docIds: string[] = [];
        docIds.push(documentId);
        await CommonService.updateDocBillingStatus(id, docIds)
            .then((result) => {
            })
            .catch((error) => {
                renderErrors(error.message);
            });
    };


    const handleViewStatus = () => {
        setIsEditing(true);
        setIsDocumentAlreadyBilled(documentDetail.isDocumentAlreadyBilled);
    }

    const handleShowErrrMsg = (e, selectdataItemedClaimData, claimStatus) => {
        if (selectdataItemedClaimData.errors != null && claimStatus === 'Rejected') {
            if (selectdataItemedClaimData.errors.length > 0) {
                showClaimError(true);
                setClaimSelectedData(selectdataItemedClaimData.errors);
            }
        }
    }
    const handleClose = () => {
        showClaimError(false);
    };

    const handleViewEOB = (e, eobId) => {
        dispatch(selectEOBId(eobId))
        navigate(APP_ROUTES.BILLING_TRANSACTION)
    }

    const handleServiceLine = (item) => {
        setClaimId(item?.claimId)
        setIsOpenServiceLineModel(!isOpenServiceLineModel)
    }


    const MyItemRender = (props: ListViewItemProps) => {

        let item: DocumentBillingHistory = props.dataItem;
        if (item.amtPaid !== '$0.00') {
            setAmtPaid(item.amtPaid)
        }
        return (
            <div className="row border-bottom mx-0">

                <div className="col-md-2 ">
                    {moment.utc(item.utcCreatedDate).local().format("M/D/YYYY")}
                </div>
                <div className="col-md-2">
                    <div
                        onClick={(e) => {
                            dispatch(selectBatchId(item.batchId))
                            navigate(APP_ROUTES.BILLING_TRANSACTION)
                        }}

                        className="cursor-pointer text-theme "
                    >
                        {item.batchId}

                    </div>
                </div>
                <div className="col-md-2"
                    onClick={(e) => { handleShowErrrMsg(e, item, item?.claimStatus); }}>
                    {item.claimStatus && <span className={(item.claimStatus &&
                        (item.claimStatus.startsWith('Accepted') || item.claimStatus === 'Paid')) ? "mb-2 text-success" :
                        (item.claimStatus.startsWith('Rejected')) ? "mb-2 text-danger underlineRed cursor-pointer" :
                            (item.claimStatus === 'Denied') ? "mb-2 text-danger" :
                                (item.claimStatus.startsWith('Pending') || item.claimStatus === 'P') ? "mb-2" :
                                    (item.claimStatus.startsWith('Partial Pay')) ? "mb-2 text-warning" : ""}>{item.claimStatus}</span>}
                </div>

                <div className="col-md-2">
                    {item.claimControlNumber}
                </div>

                <div className="col-md-2">{item.client_payerName}
                </div>
                <div className="col-md-2">
                    <div
                        onClick={(e) => {
                            if (item.eobId > 0) {
                                dispatch(selectEOBId(item.eobId))
                                navigate(APP_ROUTES.BILLING_REMITTANCE)
                            }
                        }}
                        className={(item.payerClaimNum != null && item.payerClaimNum !== "NA") ? "cursor-pointer text-theme " : ""} >
                        {item.payerClaimNum === null ? "NA" : item.payerClaimNum}
                    </div>
                    <div className="ml-1">
                        {(item.payerClaimNum != null && item.payerClaimNum !== "NA" && item.totalServiceLines >= 1) ? <div className="cursor-pointer text-theme" onClick={() => handleServiceLine(item)}>({`${item.totalServiceLines} Srv. Lines)`}</div> : ""}
                    </div>
                </div>



            </div>
        );
    }

    const showAdjDescription = (billingHistory) => {
        return (
            <div>
                {
                    billingHistory.map((item) => {
                        if (item.adjCode != null && item.adjCode !== "") {
                            return (
                                <><p className="text-danger"><b>Denial Reason: {item.adjCode}</b> - {item.adjCodeExplanation}</p></>
                            );
                        }
                    })
                }
            </div>
        );
    }






    const handleChange = (e) => {
        const value = e.target.value;
        setSelectedStatus(value);
        setIsEditing(false);
        setSelectedStatus(value);
        if (value?.id) {
            updateBillingStatus(value?.id);
        }
    };

    const handleBillingComments = (id) => {
        setIsBillingCommentOpen(!isBillingCommentOpen)
        setCommentSelectedId(id)

    }
    const handleEditBillingComment = (id) => {
        setCommentSelectedId(id)
        setEditBillingComment(!editBillingComment)
    }

    const handleBillingComment = (id) => {
        setIsDeleteBillingComment(!isDeleteBillingComment)
        setSelectedDeleteId(id)
        if (isDeleteBillingComment) {
            setScroll('false')
        }
        // else {
        //     setScroll('true')
        // }
    }

    async function fetchBillingComments() {
        setLoading(true);
        return apiHelper.getRequest(API_ENDPOINTS.GET_BILLING_COMMENT_BY_DOCUMENT_ID + documentId)
            .then((result) => {
                const data = result?.resultData
                setBillingComments(data)
            })
            .catch((err) => {
                showError(err, "Fetch Billing Comments");
            })
            .finally(() => {
                setLoading(false);
            });
    }



    const MyHeader = () => {
        return (
            <ListViewHeader
                style={{
                    color: "#000000",
                    fontSize: 20,
                }}
                className="px-3 py-2 "
            >
                <div className="row border-bottom align-middle">
                    <div className="col-2 ">
                        <h2 className="f-14">
                            Date
                        </h2>
                    </div>
                    <div className="col-2">
                        <h2 className="f-14">
                            Batch ID
                        </h2>
                    </div>

                    <div className="col-2">
                        <h2 className="f-14">
                            Status
                        </h2>
                    </div>
                    <div className="col-2">
                        <h2 className="f-14">
                            Pat. Acct #
                        </h2>
                    </div>
                    <div className="col-2">
                        <h2 className="f-14">
                            Payer
                        </h2>
                    </div>
                    <div className="col-2">
                        <h2 className="f-14">
                            Payer Claim #
                        </h2>
                    </div>

                </div>
            </ListViewHeader>
        );
    };

    function handleDocumentAction() {
        setIsOpenDocumentActionModel(!isOpenDocumentActionModel)
    }
    return (
        <div>

            <div className="widget-box widget-width">
                <ExpansionPanel
                    title="Billing"
                    expanded={expDocBilling}
                    onAction={(e) => setExpDocBilling(!e.expanded)}
                >
                    {(expDocBilling && ViewBillingStatus) && (
                        <ExpansionPanelContent>
                            <div>

                                {/* <div className="show-height-common white-scroll"> */}
                                <div>
                                    <div>
                                        <ul className="list-unstyled mb-0 details-info ">
                                            <div className="row mt-4" >
                                                <div className="">
                                                    {
                                                        isDocumentAlreadyBilled && isEditing &&
                                                        <p className="d-flex mt-3 alert alert-warning">
                                                            This document is already billed!
                                                        </p>
                                                    }

                                                </div>
                                                <div className="billstatus_cus align-items-center col-md-4 parent"
                                                    onClick={() => {
                                                        if (userIsSuperAdmin(staffLoginInfo?.roleId) &&
                                                            selectedStatus && (selectedStatus?.id?.toString()
                                                                === permissionEnum.DOCUMENT_BILLING_STATUS_PAID.toString())) {
                                                            setIsOpenDocumentActionModel(true)
                                                        }
                                                    }}>
                                                    <p className="mb-3  fw-500 labelColor">
                                                        Billing Status
                                                    </p>
                                                    <p className="bill_main  mb-0 anchar-pointer text-theme">
                                                        {
                                                            (userIsSuperAdmin(staffLoginInfo?.roleId) &&
                                                                selectedStatus && (selectedStatus?.id?.toString()
                                                                    === permissionEnum.DOCUMENT_BILLING_STATUS_PAID.toString())) ?
                                                                <DropDownList
                                                                    data={billStatusData}
                                                                    onChange={handleChange}
                                                                    textField="name"
                                                                    opened={false}
                                                                    disabled={(!userIsSuperAdmin(staffLoginInfo?.roleId) &&
                                                                        selectedStatus && (selectedStatus?.id?.toString()
                                                                            === permissionEnum.DOCUMENT_BILLING_STATUS_PAID.toString()))}
                                                                    name="selectedStatus"
                                                                    value={selectedStatus}
                                                                    className="rebill_input fw-600"
                                                                    dataItemKey="id"
                                                                /> :
                                                                <DropDownList
                                                                    data={billStatusData}
                                                                    onChange={handleChange}
                                                                    textField="name"
                                                                    disabled={(!userIsSuperAdmin(staffLoginInfo?.roleId) &&
                                                                        selectedStatus && (selectedStatus?.id?.toString()
                                                                            === permissionEnum.DOCUMENT_BILLING_STATUS_PAID.toString()))}
                                                                    name="selectedStatus"
                                                                    value={selectedStatus}
                                                                    className="rebill_input fw-600"
                                                                    dataItemKey="id"
                                                                />
                                                        }
                                                    </p>


                                                </div>

                                                <div className="mt-2 billstatus_cus align-items-center col-md-12 parent">
                                                    {
                                                        (!userIsSuperAdmin(staffLoginInfo?.roleId) &&
                                                            selectedStatus && (selectedStatus?.id?.toString()
                                                                === permissionEnum.DOCUMENT_BILLING_STATUS_PAID.toString())) &&
                                                        <p className="text-warning">{`(You're not authorized to change "Paid" status)`}</p>
                                                    }
                                                </div>

                                                {rolePermisson.isBillingManager &&
                                                    <><div className="col-md-2 mb-3">
                                                        <p className="mb-0 fw-600 labelColor">
                                                            Rate
                                                        </p>
                                                        <p className="mb-0   pt-3 fw-600 valueColor">
                                                            {documentDetail?.billingRate > 0 ? <NumericFormat value={Number(documentDetail?.billingRate).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> : ""}
                                                        </p>
                                                    </div><div className="col-md-2 mb-3">
                                                            <p className="mb-0 fw-600 labelColor">
                                                                Units
                                                            </p>
                                                            {(documentDetail?.billingUnits && (documentDetail?.billingUnits > 0)) ? <p className="mb-0  col-md-8 pt-3 fw-600 valueColor">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        {documentDetail?.billingUnits}
                                                                    </div>
                                                                    {
                                                                        (userIsSuperAdmin(staffLoginInfo?.roleId)) ?
                                                                            <div className="col-md-6 p-0">
                                                                                <Tooltip anchorElement="target" position="top">
                                                                                    {authId ? (
                                                                                        <i
                                                                                            title="Return Authorization Units"
                                                                                            onClick={() => handleRemoveRestoreUnits(true)}
                                                                                            className="cursor-pointer fa-regular fa-circle-xmark fa-lg ml-3"
                                                                                        ></i>
                                                                                    ) : (
                                                                                        <i
                                                                                            title="Restore/Link Authorizations to Document"
                                                                                            onClick={() => handleRemoveRestoreUnits(false)}
                                                                                            className="cursor-pointer fa fa-rotate-left fa-lg ml-2"
                                                                                        ></i>
                                                                                    )}
                                                                                </Tooltip>
                                                                            </div>
                                                                            : ""}
                                                                </div>
                                                            </p> : ""}
                                                        </div><div className="col-md-2 mb-2 ">
                                                            <p className="mb-0 fw-600 labelColor">
                                                                Total Bill
                                                            </p>
                                                            <p className="mb-0  col-md-8 pt-3 pl-0 fw-600 valueColor">
                                                                {documentDetail?.totalBilledAmount > 0 ? <NumericFormat value={Number(documentDetail?.totalBilledAmount).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> : ""}
                                                            </p>
                                                        </div><div className="col-md-2 mb-2">
                                                            <p className="mb-0 fw-600 labelColor">
                                                                Amt Paid
                                                            </p>
                                                            <p className="mb-0  col-md-8 pt-3 pl-0 fw-600 valueColor">
                                                                <span className={"mb-2 text-success"}>{amtPaid}</span>
                                                            </p>
                                                        </div></>}


                                            </div>



                                            {(billingHistory) && billingHistory?.length > 0 && rolePermisson.isBillingManager &&
                                                <div className="grid-table filter-grid dash-filter-grid my-3  boxShadowNone">
                                                    <h5 className="labelColor">Billing History</h5>

                                                    <div >
                                                        <ListView
                                                            data={billingHistory}
                                                            item={MyItemRender}
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                            header={MyHeader}
                                                        />
                                                        <div style={{ margin: 15 }}>{showAdjDescription(billingHistory)}</div>
                                                    </div>



                                                </div>}

                                            {rolePermisson.isBillingManager &&
                                                <div className="row mt-3  ">
                                                    <div className="col-8  fw-500  labelColor">
                                                        <p className="mb-0 fw-500 labelColor">
                                                            Billing Comments
                                                        </p>
                                                    </div>
                                                    <div className="col-md-4 col-12">
                                                        <div className="text-right">
                                                            <button
                                                                className="submitButon"
                                                                onClick={handleBillingComments}
                                                            >
                                                                Add Comments
                                                            </button>

                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <li className="d-flex mb-3">
                                                            <p className="mb-0 col-md-12 pt-2 pl-0">
                                                                <ul className="pl-0">
                                                                    {billingComments.map((list: any, index) => (
                                                                        <div className="border rounded mb-1 " >
                                                                            <li key={index} className="d-flex p-2 " style={{ justifyContent: "space-between" }}>
                                                                                <p className="commented_para mb-1">{`commented by ${list?.createByStaffName} on ${moment.utc(list?.utcDateCreated).local().format("M/D/YYYY")} at ${moment.utc(list?.utcDateCreated).local().format("hh:mm A")}`}

                                                                                    <p className="valueColor mt-1 justify-content">{list?.comment} </p>
                                                                                </p>
                                                                                <div className="d-flex ml-3 justify-content-space-between" >
                                                                                    <Tooltip anchorElement="target" position="top">
                                                                                        {/* <i
                                                                                            className="fa fa-trash fa-sm mr-2"
                                                                                            aria-hidden="true"
                                                                                            title="Delete"
                                                                                            onClick={() => handleBillingComment(list?.id)}
                                                                                        ></i> */}
                                                                                        <img src={trashIcon} alt="trash icon" onClick={() => handleBillingComment(list?.id)} aria-hidden="true"
                                                                                            title="Delete" />
                                                                                    </Tooltip>
                                                                                    <Tooltip anchorElement="target" position="top">
                                                                                        {/* <i
                                                                                            style={{ cursor: "pointer" }}
                                                                                            className="fa fa-pencil fa-sm "
                                                                                            aria-hidden="true"
                                                                                            title="Edit"
                                                                                            onClick={() => handleEditBillingComment(list?.id)}
                                                                                        ></i> */}
                                                                                        <img
                                                                                            src={editPencil}
                                                                                            alt=" edit "
                                                                                            style={{ maxWidth: "18px", marginLeft: "10px" }} aria-hidden="true"
                                                                                            title="Edit"
                                                                                            onClick={() => handleEditBillingComment(list?.id)}
                                                                                            className="cursor-pointer"
                                                                                        />
                                                                                    </Tooltip>
                                                                                </div>
                                                                            </li>
                                                                        </div>
                                                                    ))}

                                                                </ul>
                                                            </p>
                                                        </li></div>
                                                </div>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </ExpansionPanelContent>
                    )}
                </ExpansionPanel>
                {isBillingCommentOpen && <BillingComments onClose={handleBillingComments} documentId={documentId} fetchBillingComments={fetchBillingComments} CommentSelectedId={null} />}
                {editBillingComment && <EditBillingComments onClose={handleEditBillingComment} documentId={documentId} CommentSelectedId={commentSelectedId} fetchBillingComments={fetchBillingComments} />}
                {isDeleteBillingComment && <DeleteBillingComment onClose={handleBillingComment} fetchBillingComments={fetchBillingComments} selectedDeleteId={selectedDeleteId} />}
                {showRemoveDialog &&
                    <CommonActionDialog
                        handleOperation={handleYes}
                        btn1Text="Yes"
                        btn2Text="No"
                        onClose={() => setShowRemoveDialog(false)}
                        title={'Return Authorization Units'}
                        message={`Are you sure you want to return units to auth #${custAuthId} ?`}
                    />
                }

            </div>
            {errorIsVisible && (
                <ViewRejectionErrors onClose={handleClose} claimErrors={claimErrors} />
            )}
            {isOpenDocumentActionModel &&
                <DocumentActionModel
                    preSelectedStatus={selectedStatus}
                    userIsSuperAdmin={userIsSuperAdmin(staffLoginInfo?.roleId)}
                    onClose={handleDocumentAction}
                    checkedDocuments={[{ documentId: documentId, serviceDate: documentDetail.serviceDate, billingStatusId: documentDetail.billingStatusId, clientName: documentDetail.clientNameDoc }]}
                    fetchDocuments={(value) => {
                        setSelectedStatus(value)
                        fetchBillingComments()
                    }}
                    resetCheckedDocuments={() => { }} />

            }
            {isOpenServiceLineModel && (<ServiceLineModel onClose={handleServiceLine} label="Service Line" claimId={claimId} />)}
        </div>
    );
};
export default ViewBillingStatus;
