import React, { useEffect, useRef, useState } from 'react';
import { APIProvider, InfoWindow, Marker } from '@vis.gl/react-google-maps';

interface Client {
    id: string;
    clientName: string;
    address: string;
    city: string;
    state: string;
    zipcode: string;
    latitude: number;
    longitude: number;
}

interface MapComponentProps {
    apiKey: string;
    clientsForCaseloadMap: Client[];
}

function MapComponent({ apiKey, clientsForCaseloadMap }: MapComponentProps) {
    const markersRef = useRef<google.maps.Marker[]>([]); 
    const mapContainerRef = useRef<HTMLDivElement | null>(null);
    const mapInstanceRef = useRef<google.maps.Map | null>(null);
    const [isMapReady, setIsMapReady] = useState(false);

    const initializeMap = () => {
        if (!mapInstanceRef.current && mapContainerRef.current && window.google) {
            mapInstanceRef.current = new window.google.maps.Map(mapContainerRef.current, {
                center: { lat: 39.8283, lng: -98.5795 },
                zoom: 4,
            });
            setIsMapReady(true);
        }
    };

    useEffect(() => {
        if (!isMapReady  || !mapInstanceRef.current) {
            return;
        }

        const bounds = new google.maps.LatLngBounds();

        markersRef.current.forEach((marker) => marker.setMap(null));
        markersRef.current = [];
        const infoWindow = new google.maps.InfoWindow();
        clientsForCaseloadMap.forEach((client) => {
            const marker = new google.maps.Marker({
                position: { lat: client.latitude, lng: client.longitude },
                map: mapInstanceRef.current,
            });
            marker.addListener("mouseover", () => {
                const contentString = `
                    <div>
                        <h6>${client.clientName}</h6>
                        <p class="mb-0">${client.address}</p>
                        <p class="mb-1">
                            ${client.city}, ${client.state} ${client.zipcode}
                        </p>
                    </div>
                `;
    
                infoWindow.setContent(contentString);
                infoWindow.open(mapInstanceRef.current, marker);
            });
            marker.addListener("mouseout", () => {
                infoWindow.close(); 
            });
            markersRef.current.push(marker);
            bounds.extend(marker.getPosition() as google.maps.LatLng);
        });

        if (clientsForCaseloadMap.length > 0) {
            mapInstanceRef.current.fitBounds(bounds);
        }

    }, [clientsForCaseloadMap, isMapReady]);

    return (
        <APIProvider 
        apiKey={apiKey} 
        onLoad={() => {
            initializeMap();
        }}>
            <div style={{ height: '900px' }}>
                <div ref={mapContainerRef} style={{ height: '100%', width: '100%' }} />

            </div>
        </APIProvider>
    );
}

export default MapComponent;
