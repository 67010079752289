import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { assignReportsToRole, assignReportToMultipleStaff, getAssignedStaffOfReport, getRoleReports } from "src/redux/actions";
import { NotificationManager } from "react-notifications";
import { ListBox, ListBoxToolbar, processListBoxData, processListBoxDragAndDrop } from "@progress/kendo-react-listbox";
import { API_ENDPOINTS } from "src/services/api-endpoints";
import apiHelper from "../../helper/api-helper.js";
import { renderErrors } from "src/helper/error-message-helper.js";

const SELECTED_FIELD = "selected";
function Tab2({ roleData = [], selectedReport }: { roleData: any[]; selectedReport: number }) {
    const [selectedRoles, setSelectedRoles] = useState<number[]>([]);
    const [available, setAvailable] = useState([] as any[]);
    const [assigned, setAssigned] = useState([] as any[]);
    const [stateData, setState] = useState({
        available: [] as any[],
        assigned: [] as any[],
        draggedItem: {},
    });
    const [loading, setLoading] = useState(false);
    const [selectedID, setSelectedID] = useState([] as any[]);
    const lastSelectedIndex = useRef(0);
    const dispatch = useDispatch();
    const { roleReports, assignedStaffForReport } = useSelector((state: any) => state.StaffReducer);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const roleId = parseInt(event.target.name, 10);
        const isChecked = event.target.checked;

        if (isChecked) {
            setSelectedRoles((prevSelectedRoles) => [...prevSelectedRoles, roleId]);
        } else {
            setSelectedRoles((prevSelectedRoles) =>
                prevSelectedRoles.filter((id) => id !== roleId)
            );
        }
    };

    const handleUpdateClick = () => {
        if(selectedReport) {
            const data = {
                roleIds: selectedRoles,
                reportIds: [selectedReport],
            };
            dispatch(assignReportsToRole(data));
        }
        else {
            NotificationManager.error('Please select a report to continue')
        }
    };

    useEffect(() => {
        dispatch(getRoleReports())
    }, [selectedReport])

    useEffect(() => {
        fillData();
    }, [available, assigned]);

    useEffect(() => {
        AvailableStaff()
        AssignedStaff()
    }, [selectedReport])

    useEffect(() => {
      if (roleReports) {
          const filteredResults = roleReports.filter(
              (report: any) => report.reportId === selectedReport
          );
          const roleIds = filteredResults.map((role: any) => role.roleId);
          
          const requiredRole = 1 
          if (!roleIds.includes(requiredRole)) {
              roleIds.push(requiredRole);
          }
  
          setSelectedRoles(roleIds);
      }
  }, [roleReports, selectedReport]);
  
        // comp
    function getavailableList() {
        return !available
          ? []
          : available.map((x) => {
            return { selected: false, ...x };
          });
      }
    
      function getAssignedList() {
        return !assigned
          ? []
          : assigned.map((item) => {
            return { selected: false, ...item };
          });
      }
      async function fillData() {
        let AssignedList = getAssignedList();
        setState({
          ...stateData,
          available: getavailableList().filter(
            (available) =>
              !AssignedList.find(
                (assigned) =>
                  assigned.staffId === available.staffId ||
                  assigned.staffId === available.staffId
              )
          ),
          assigned: AssignedList,
          draggedItem: {},
        });
      }
    
    
      const AvailableStaff = () => {
        setLoading(true);
        apiHelper
          .getRequest(API_ENDPOINTS.GET_STAFF_DDL_BY_CLINIC_ID + "true")
          .then((result) => {
            const data = result.resultData.map((item) => {
              return {
                staffId: item.id,
                staffName: item.name
              }
            });
    
            setAvailable(data);
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            renderErrors(error.message);
    
          });
      };
    
      const AssignedStaff = () => {
        setLoading(true);
        const data = {
            reportId: selectedReport
        }
        dispatch(getAssignedStaffOfReport(data))
        setLoading(false); 
      };

      useEffect(() => {
        if(assignedStaffForReport.length > 0) {
            const data = assignedStaffForReport.map((item) => ({
                staffId: item.staffId,
                staffName: item.staffName,
            })); 
            setAssigned(data); 
        }
        else {
            setAssigned(assignedStaffForReport)
        }
      }, [assignedStaffForReport])
      
    
      const handleItemClick = (event, data, connectedData) => {
        let last = lastSelectedIndex.current;
        const newData = [...stateData[data]];
        let id = [...selectedID];
        const current = newData.findIndex(
          (dataItem) => dataItem.staffId === event.dataItem.staffId
        );
    
        if (!event.nativeEvent.shiftKey) {
          lastSelectedIndex.current = last = current;
        }
    
        if (!event.nativeEvent.metaKey && !event.nativeEvent.ctrlKey) {
          newData.forEach((item) => (item.selected = false));
          id = [];
        }
    
        const select = !event.dataItem.selected;
    
        for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
          newData[i].selected = select;
        }
        setState({
          ...stateData,
          [data]: newData?.map((item) => {
            if (item.staffId === event.dataItem.staffId) {
              id.push(item?.staffId);
              setSelectedID(id);
            }
            return item;
          }),
        });
      };

    const handleToolBarClick = async (e) => {
        setLoading(true);
        let toolName = e.toolName || "";
        let result = processListBoxData(
          stateData.available,
          stateData.assigned,
          toolName,
          SELECTED_FIELD
        );
    
        let body = {
        //   thresholdUnits: fields?.thresholdUnits,
        //   freqencyDays: fields?.freqencyDays,
        //   isActive: fields?.isActive,
          staffIds: Array.from(
            new Set(result.listBoxTwoData.map((item) => item.staffId))
          ),
          reportId: selectedReport
        };
        try {
          dispatch(assignReportToMultipleStaff(body))
    
          setLoading(false);
        } catch (error) {
          renderErrors(error);
          setLoading(false);
        }
    
        setState({
          ...stateData,
          available: result.listBoxOneData,
          assigned: result.listBoxTwoData.map((item) => ({
            ...item,
            staffId: item?.staffId || item?.staffId,
          })),
        });
      };
    
      const handleDragStart = (e) => {
        setState({ ...stateData, draggedItem: e.dataItem });
      };
    
      const handleDrop = (e) => {
        let result = processListBoxDragAndDrop(
          stateData.available,
          stateData.assigned,
          stateData.draggedItem,
          e.dataItem,
          "staffName"
        );
        setState({
          ...stateData,
          available: result.listBoxOneData,
          assigned: result.listBoxTwoData,
        });
      };

    return (
        <>
            <div className="my-3 gap-2 obj-heading">
                <h4 className="f-20 address-title text-grey m-0" style={{ textAlign: "left" }}>
                    Assign Reports to selected roles
                </h4>
            </div>
            <div className="my-2 d-flex flex-wrap">
                {Array.isArray(roleData) &&
                    roleData.map((role: any) => (
                        <div key={role.id} className="mt-2 ml-5">
                            <label>
                                <input
                                    type="checkbox"
                                    name={role.id.toString()}
                                    checked={selectedRoles.includes(role.id)}
                                    onChange={handleCheckboxChange}
                                    disabled={role.id === 1}
                                />
                                {role.roleName}
                            </label>
                        </div>
                    ))}
                <div>
                    <button
                        className="submitButon f-14 d-flex align-items-center ml-5 my-2"
                        onClick={handleUpdateClick}
                    >
                        Update
                    </button>
                </div>
            </div>
            <div className="my-3 gap-2 obj-heading">
                <h4 style={{ textAlign: "left" }} className="pl-0 f-20 address-title text-grey m-0 obj-heading mb-2">Assign selected report to selected staff</h4>
            </div>
            <div className="d-flex flex-wrap">
                <div className="col k-pr-2 order-given">
                <h6>Available</h6>
                <ListBox
                    className="k-reset-assign-up"
                    style={{
                    height: 400,
                    width: "100%",
                    }}
                    data={stateData.available}
                    textField="staffName"
                    selectedField={SELECTED_FIELD}
                    onItemClick={(e) => handleItemClick(e, "available", "assigned")}
                    onDragStart={handleDragStart}
                    onDrop={handleDrop}
                    toolbar={() => {
                    return (
                        <>
                        <ListBoxToolbar
                            tools={[
                            "transferTo",
                            "transferFrom",
                            "transferAllTo",
                            "transferAllFrom",
                            ]}
                            data={stateData.available}
                            dataConnected={stateData.assigned}
                            onToolClick={handleToolBarClick}
                        />
                        </>
                    );
                    }}
                />
            </div>
            <div className=" col-md-6 col k-pl-0">
            <h6>Assigned</h6>
            <ListBox
                style={{
                  height: 400,
                  width: "100%",
                }}
                data={stateData.assigned}
                textField="staffName"
                selectedField={SELECTED_FIELD}
                onItemClick={(e) => handleItemClick(e, "assigned", "available")}
                onDragStart={handleDragStart}
                onDrop={handleDrop}
            />
            </div>
        </div>

        </>
    );
}

export default Tab2;
