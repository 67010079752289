import React, { useEffect, useState } from "react";
import { Loader } from "@progress/kendo-react-indicators";
import { useSelector, useDispatch } from "react-redux";
import { Dialog } from "@progress/kendo-react-dialogs";
import TextAreaKendoRct from "src/control-components/text-area/text-area";
import { addUpdateStaffComment } from "src/redux/actions";
import { Error } from "@progress/kendo-react-labels";

const AddStaffComment = ({ onClose, comments }) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState("");
  const [settingError, setSettingError] = useState(false);
  const selectedStaffId = useSelector((state: any) => state?.selectedStaffId);
  const dispatch = useDispatch();

  let [fields, setFields] = useState({
    comments: comments,
  });

  useEffect(() => {
    setFields((fields) => ({ ...fields, comments: comments }));
  }, [comments]);

  const handleSubmit = () => {
    setSettingError(true);
    addUpdateComment();
  };

  const addUpdateComment = async () => {
    if (fields.comments) {
      const params = {
        staffId: selectedStaffId,
        comments: fields?.comments,
      };
      dispatch(addUpdateStaffComment(params));
      onClose({ updated: true });
    } else {
      setErrors("Comment is required");
    }
  };

  const handleChange = (e:any) => {
    const { name, value } = e.target;

    if (value?.length <= 2000) {
      setFields({
        ...fields,
        [name]: value,
      });
      setErrors("");
    } else {
      setErrors("You can only enter up to 2000 characters.");
    }
  };

  return (
    <div>
      <Dialog
        onClose={onClose}
        title={
          comments && comments?.length > 0 ? "Edit Comment" : "Add Comment"
        }
        className=" flag width_90 SmallHEight blue_theme"
        width={"50%"}
        height="60%"
      >
        <div className="edit-client-popup">
          <div className="popup-modal slibling-data">
            <div className="">
              <div>
                <TextAreaKendoRct
                  txtValue={fields?.comments}
                  onChange={handleChange}
                  name="comments"
                  label="Comment"
                />
                <p>{fields.comments ? 2000 - fields?.comments?.length : "2000"} characters remaining</p>
                <Error>{!fields.comments && errors}</Error>
              </div>
            </div>
          </div>

          {loading === true && <Loader />}
        </div>
        <div className="preview-btn-bottom ">
          <div className="border-bottom-line"></div>
          <div className="d-flex my-3 mr-3">
            <div className="right-sde">
              <button className="submitButon px-4 " onClick={handleSubmit}>
                {comments && comments?.length > 0 ? "Update" : "Add"}
              </button>
            </div>
            <div className="right-sde-grey">
              <button onClick={onClose} className="cancelBtn px-4 ">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default AddStaffComment;

