import { Dialog } from "@progress/kendo-react-dialogs";
import { useDispatch, useSelector } from "react-redux";
import { Switch } from '@progress/kendo-react-inputs';
import { useEffect, useState } from "react";
import { getStaffReportColumnPreferences, insertStaffReportColPreferences } from "src/redux/actions";

function SettingTemplateReport({ onClose, selectedReport }: { onClose: any, selectedReport: number }) {
    const { reportColumns, staffReportColumnPrefResp } = useSelector((state: any) => state.StaffReducer);
    const staffId = useSelector((state: any) => state.loggedIn?.staffId);

    const dispatch = useDispatch();

    const [fields, setFields] = useState(
        reportColumns.reduce((acc, column) => {
            acc[column.id] = { booleanForColumn: false };
            return acc;
        }, {})
    );

    useEffect(() => {
        const updatedFields = { ...fields };
        staffReportColumnPrefResp.forEach(pref => {
            if (pref.isActive) {
                updatedFields[pref.reportColumnId].booleanForColumn = true;
            }
        });
        setFields(updatedFields);
    }, [staffReportColumnPrefResp]);

    const handleChange = (event, columnId) => {
        setFields(prevFields => ({
            ...prevFields,
            [columnId]: {
                ...prevFields[columnId],
                booleanForColumn: event.value
            }
        }));
    };

    function handleUpdateSettingsReport() {
        const reportColumnIds = Object.keys(fields)
            .filter(columnId => fields[columnId].booleanForColumn)
            .map(columnId => parseInt(columnId));

        const data = {
            reportId: selectedReport,
            staffId: staffId,
            reportColumnIds: reportColumnIds
        };

        dispatch(insertStaffReportColPreferences(data));
        onClose();
    }

    useEffect(() => {
        const data = {
            staffId: staffId,
            reportId: selectedReport
        };
        dispatch(getStaffReportColumnPreferences(data));
    }, []);

    return (
        <Dialog
            onClose={onClose}
            title="Report Template Settings"
            className="small-dailog medium-modal width_90 blue_theme"
            width={"150%"} height={"65%"}
        >
            <div className="column mt-3 ">
                <div className="row m-0">
                    {reportColumns.map(column => (
                        <div key={column.id} className="col-md-6 col-lg-6 col-6 mb-3">
                            <div className="d-flex align-items-center">
                                <Switch
                                    value={fields[column.id]?.booleanForColumn}
                                    name={`booleanForColumn-${column.id}`}
                                    onChange={(e) => handleChange(e, column.id)}
                                    checked={fields[column.id]?.booleanForColumn}
                                />
                                <span className="switch-title-text ml-2 fw-400 f-16 d-flex align-items-center">
                                    {column.uiColumnName}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <button className="submitButon f-14 d-flex align-items-center ml-3 my-2" onClick={handleUpdateSettingsReport}>
                Update
            </button>
        </Dialog>
    );
}

export default SettingTemplateReport;
