import React, { ComponentState, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { ClientService } from "src/services/clientService";
import APP_ROUTES from "src/helper/app-routes";
import { renderErrors } from "src/helper/error-message-helper";
import NotificationManager from "react-notifications/lib/NotificationManager";

import { NichqHeader } from "./Nichq-heads";
import { QIDSheads } from "./QIDS-heads";






export const QIDS_Adolescent = ({ QIDS_A17, QIDS_A17_SCORING, QIDS_A17_HEADERS, response, interfered, isPrint, questionId }) => {

  const [qidsAdolescent, setQidsAdolescent] = useState<any[]>([]);
  const { state } = useLocation();
  const selectedClientId = useSelector((state: ComponentState) => state.selectedClientId);
  const navigate = useNavigate();


  const [qidsAdolescentScoring, setQidsAdolescentScoring] = useState<any[]>([]);
  const [qidsAdolescentHeaders, setQidsAdolescentHeaders] = useState<any[]>([]);


  useEffect(() => {

    const listQidsAdolescent = QIDS_A17.map((item) => {
      const foundItem = response.find((i) => i.questionId === item.questionId);
      if (foundItem) {
        if (item.type === "question") {
          if (foundItem.value) {
            return { ...item, isSelected: true, value: foundItem.value };
          } else {
            return { ...item, isSelected: false, value: "" };
          }
        }
        return { ...item, ...foundItem };
      } else {
        return item;
      }
    });
    const QidsAdolescentScoring = QIDS_A17_SCORING.map((item) => {
      const foundItem = response.find((i) => i.questionId === item.questionId);
      if (foundItem) {
        if (item.type === "question") {
          if (foundItem.value) {
            return { ...item, isSelected: true, value: foundItem.value };
          } else {
            return { ...item, isSelected: false, value: "" };
          }
        }
        return { ...item, ...foundItem };
      } else {
        return item;
      }
    });
    const QidsAdolescentHeaders = QIDS_A17_HEADERS.map((item) => {
      const foundItem = response.find((i) => i.questionId === item.questionId);
      if (foundItem) {
        if (item.type === "question") {
          if (foundItem.value) {
            return { ...item, isSelected: true, value: foundItem.value };
          } else {
            return { ...item, isSelected: false, value: "" };
          }
        }
        return { ...item, ...foundItem };
      } else {
        return item;
      }
    });


    setQidsAdolescent(listQidsAdolescent)
    setQidsAdolescentScoring(QidsAdolescentScoring)
    setQidsAdolescentHeaders(QidsAdolescentHeaders)



  }, [response])

  const handleSubmit = async () => {
    await ClientService.insertClientQuestionnaire(
      selectedClientId, 0,
      [
        ...qidsAdolescent,
        ...qidsAdolescentScoring,
        ...qidsAdolescentHeaders,
      ],
      questionId
    )
      .then((result) => {
        let questionList = result.resultData;
        navigate(APP_ROUTES.QUESTIONNAIRE);
        NotificationManager.success("Add successfully");
      })
      .catch((error) => {
        // setLoading(false);
        renderErrors(error.message);
      });
  };

  return (
    <div className="ml-3"> 


      <div className="mt-4 head-4">

        QUICK INVENTORY OF DEPRESSIVE SYMPTOMATOLOGY - ADOLESCENT (17 ITEM) - CLINICIAN RATED
        <br />
        (QIDS-A17-C)
      </div>


      {
        qidsAdolescentHeaders.length > 0 &&
        <div className="row mt-4">
          <NichqHeader

            title={""}
            disabled={response && response.length > 0}
            subTitle={''}
            listItems={qidsAdolescentHeaders}
            setListItems={setQidsAdolescentHeaders}
          />
        </div>
      }





      {
        qidsAdolescent.length > 0 &&
        <div className="row mt-4 mr-5 ">
          <NichqHeader

            title={""}
            disabled={response && response.length > 0}
            subTitle={''}
            listItems={qidsAdolescent}
            setListItems={setQidsAdolescent}
          />
        </div>

      }

      <div>
        <b>Scoring Criteria:</b>


        <div className="row">
          <div className="col-md-4"><b>0-5 </b></div>
          <div className="col-md-8">Not Depressed </div>
        </div>
        <div className="row">
          <div className="col-md-4"><b>6-10 </b></div>
          <div className="col-md-8">Mild </div>
        </div>
        <div className="row">
          <div className="col-md-4"><b>11-15 </b></div>
          <div className="col-md-8">Moderate </div>
        </div>
        <div className="row">
          <div className="col-md-4"><b>16-20 </b></div>
          <div className="col-md-8">Severe </div>
        </div>
        <div className="row">
          <div className="col-md-4"><b>  21 &#43;</b></div>
          <div className="col-md-8">Very Severe </div>
        </div>
      </div>
      <div className="mt-4">
        <b>To score:</b>
      </div>


      {
        qidsAdolescentScoring.length > 0 &&
        <div className="row mt-4">
          <QIDSheads

            title={""}
            disabled={response && response.length > 0}
            subTitle={''}
            listItems={qidsAdolescentScoring}
            setListItems={setQidsAdolescentScoring}
          /></div>
      }

      {state == null && !isPrint ? (
        <div className="text-start mt-3 col-12">
          <button onClick={handleSubmit} className="submitButon ">
            Submit
          </button>
        </div>
      ) : (
        ""
      )}






    </div>

  )

} 