import CustomDrawer from "src/control-components/custom-drawer/custom-drawer";
import ClientHeader from "../client-header/client-header";
import { GridColumn, GridNoRecords, Grid, getSelectedState } from "@progress/kendo-react-grid";
import moment from "moment";
import { Input, Switch } from "@progress/kendo-react-inputs";
import { orderBy } from "@progress/kendo-data-query";
import APP_ROUTES from "src/helper/app-routes";
import { getter } from "@progress/kendo-react-common";
import DatePickerKendoRct, { CustomCalendar } from "src/control-components/date-picker/date-picker";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { ComponentState, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Chip } from "@progress/kendo-react-buttons";
import { getServiceMinReportByClientId } from "src/redux/actions";

const DATA_ITEM_KEY = "id";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);
const MinServiceReport = () => {
    const dispatch = useDispatch()

    const state: ComponentState = useSelector(states => {
        return states;

    });
    const { minServiceReportList } = state['ClientReducer']

    const [requestObj, setRequestObj] = useState({
        serviceDate: moment().toDate(),
    });
    const [minServiceReport, setServiceReport] = useState([])
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [sort, setSort] = useState<any>([]);
    const [selectedState, setSelectedState] = useState({});

    const selectedClientId = useSelector((state: any) => state.selectedClientId);

    useEffect(() => {
        generateReport();
    }, [requestObj.serviceDate])

    function generateReport() {
        const data = {
            clientId: selectedClientId,
            serviceDate: moment(requestObj.serviceDate).startOf('day').format('YYYY-MM-DD')
        }
        dispatch(getServiceMinReportByClientId(data))
    }

    function handleChange(e) {
        const { name, value } = e.target;
        setRequestObj(prevRequestObj => ({
            ...prevRequestObj,
            [name]: value
        }));
    }

    const pageChange = (event: any) => {
        let skip = event.page.skip;
        let take = event.page.take;
        setPage(skip);
        setPageSize(take);

    };

    useEffect(() => {
        if (minServiceReportList && minServiceReportList.length > 0) {
            const list = minServiceReportList
            setServiceReport(list)
        }
    }, [minServiceReportList])
    
    function toHoursAndMinutes(totalMinutes) {
        var hours = Math.floor(totalMinutes / 60);
        var minutes = totalMinutes % 60;
        var hoursStr =  `${hours} hr ` ;
        var minutesStr =  `${minutes}` ;
        const formatedTime = `${hoursStr}${minutesStr}`;
        return formatedTime;
    }

    return (
        <div className="d-flex flex-wrap">
            <div className="inner-dt col-md-3 col-lg-2">
                <CustomDrawer />
            </div>
            <div className="col-md-9 col-lg-10">
                <ClientHeader />
                <div className="Service-RateList">
                    <div className="d-flex justify-content-between  mt-3">
                        <h4 className="address-title text-grey ">
                            <span className="f-24">Minimum Service Report</span>
                        </h4>
                    </div>
                </div>
                <div className="d-flex flex-wrap align-items-center">
                    <div className="content-search-filter">
                        <DatePicker
                            calendar={CustomCalendar}
                            format={"M/d/yyyy"}
                            name="serviceDate"
                            size={"medium"}
                            value={requestObj.serviceDate}
                            onChange={handleChange}
                            autoFocus={false}
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="grid-table  filter-grid custom-grid blueThemeTable">
                    <div className=" mt-3">
                        <Grid
                            data={
                                orderBy(
                                    minServiceReport?.slice(page, pageSize + page),
                                    sort)?.map((item: any) => ({
                                        ...item,
                                        [SELECTED_FIELD]: selectedState[idGetter(item)],
                                    }))
                            }
                            dataItemKey={DATA_ITEM_KEY}
                            selectedField={SELECTED_FIELD}
                            skip={page}
                            take={pageSize}
                            total={minServiceReport?.length}
                            onPageChange={pageChange}
                            className="pagination-row-cus"
                            pageable={{
                                pageSizes: [10, 20, 30],
                            }}
                            // sort={sort}
                            // sortable={true}
                            // onSortChange={(e) => {
                            //     setSort(e.sort);
                            // }}
                            // onSelectionChange={onSelectionChange}

                        >
                            <GridNoRecords ></GridNoRecords>

                            <GridColumn
                                className="cursor-default"
                                field="ruleName"
                                title="Rule Name"
                                cell={(props) => {
                                    let field = props.dataItem;
                                    return (
                                        <td className="k-table-td cursor-default">
                                            {`${field.ruleName}(${field.ruleType})`}
                                        </td>
                                    );
                                }}
                            />

                            <GridColumn
                                className="cursor-default"
                                field="ruleName"
                                title="Rule"
                                cell={(props) => {
                                    let field = props.dataItem;
                                    let ruleType = 'minutes'
                                    if(field.ruleType === 'unit') {
                                        ruleType = 'units'
                                    } 
                                    return (
                                        <td className="k-table-td cursor-default">
                                            {`Min. ${field.limit} ${ruleType} per ${field.period}`}
                                        </td>
                                    );
                                }}
                            />

                            <GridColumn
                                className="cursor-default"
                                field="periodStartDate"
                                title="Period"
                                cell={(props) => {
                                    let field = props.dataItem;
                                    return (
                                        <td className="k-table-td cursor-default">
                                        {field.period === 'day' 
                                        ? moment(field.periodStartDate).format('M/D/YYYY') 
                                        : `${moment(field.periodStartDate).format('M/D/YYYY')} - ${moment(field.periodEndDate).format('M/D/YYYY')}`
                                        }
                                        </td>
                                    );
                                }}
                            />

                            <GridColumn
                                className="cursor-default"
                                field="periodStartDate"
                                title={'Status'}
                                cell={(props) => {
                                    let field = props.dataItem;
                                    let ruleType = 'minutes'
                                    let ruleSatisfied = true
                                    if(field.ruleType === 'unit') {
                                        ruleType = 'units'
                                    } 
                                    if(ruleType === 'minutes' && field.totalMinutesConsumed < field.limit) {
                                        ruleSatisfied = false
                                    }
                                    else if(ruleType === 'units' && field.totalUnitsConsumed < field.limit) {
                                        ruleSatisfied = false
                                    }
                                    return (
                                        <td className="k-table-td cursor-default">
                                            <Chip
                                                text={`${
                                                    ruleType === 'minutes' 
                                                    ? `${toHoursAndMinutes(field.totalMinutesConsumed)} minutes used` 
                                                    : `${field.totalUnitsConsumed} units used`
                                                }`}
                                                value="chip"
                                                rounded={"large"}
                                                fillMode={"solid"}
                                                size={"medium"}
                                                style={{
                                                marginRight: 5,
                                                backgroundColor:
                                                    ruleSatisfied ? "green" : "red",
                                                marginBottom: 5,
                                                color: "#ffffff",
                                                }}
                                            />
                                        </td>
                                    );
                                }}
                            />
                        </Grid>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MinServiceReport