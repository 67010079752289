import { takeLatest, put } from 'redux-saga/effects';
import * as TYPES from '../actions/types';
import { AppSuccessResponse } from 'src/dataModels/apiSuccessResponse';
import { getServiceRuleList, saveServiceRuleDataLoading, saveServiceRulesList,saveMinServiceRuleDataLoading,saveMinServiceRulesList,getMinServiceRuleList} from '../actions';
import { customAxios } from 'src/services/useExPressApi';
import { API_ENDPOINTS } from 'src/services/api-endpoints';



export function* getServiceRuleListSaga() {
    yield takeLatest(TYPES.GET_SERVICE_RULE_LIST, ruleListWorker);
}

function* ruleListWorker(param: any) {
    try {
        yield put(saveServiceRuleDataLoading(true));
        const response: AppSuccessResponse = yield yield customAxios.get<any, AppSuccessResponse>(`${API_ENDPOINTS.GET_SERVICE_RULES}`,);
        yield put(saveServiceRulesList(response.resultData));
        yield put(saveServiceRuleDataLoading(false));
    } catch (err) {
    }
}


export function* deleteServiceRuleSaga() {
    yield takeLatest(TYPES.DELETE_SERVICE_RULE, deleteRuleWorker);
}

function* deleteRuleWorker(param: any) {
    try {
        yield put(saveServiceRuleDataLoading(true));
        const response: AppSuccessResponse = yield yield customAxios.delete<any, AppSuccessResponse>(`${API_ENDPOINTS.DELETE_SERVICE_RULE}${param['payload']}`);
        yield put(getServiceRuleList())
    } catch (err) {
    }
}


export function* insertServiceRuleSaga() {
    yield takeLatest(TYPES.INSERT_SERVICE_RULE, insertServiceWorker);
}

function* insertServiceWorker(param: any) {
    try {
        yield put(saveServiceRuleDataLoading(true));
        const response: AppSuccessResponse = yield yield customAxios.put<any, AppSuccessResponse>(`${API_ENDPOINTS.CREATE_SERVICE_RULE}`,param['payload']);
        yield put(getServiceRuleList())
    } catch (err) {
    }
}


export function* updateServiceRuleSaga() {
    yield takeLatest(TYPES.UPDATE_SERVICE_RULE, updateServiceWorker);
}

function* updateServiceWorker(param: any) {
    try {
        yield put(saveServiceRuleDataLoading(true));
        const response: AppSuccessResponse = yield yield customAxios.post<any, AppSuccessResponse>(`${API_ENDPOINTS.UPDATE_SERVICE_RULE}`,param['payload']);
        yield put(getServiceRuleList())
    } catch (err) {
    }
}
export function* getMinServiceRuleListSaga() {
    yield takeLatest(TYPES.GET_MIN_SERVICE_RULE_LIST, minRuleListWorker);
}

function* minRuleListWorker(param: any) {
    try {
        yield put(saveMinServiceRuleDataLoading(true));
        const response: AppSuccessResponse = yield yield customAxios.get<any, AppSuccessResponse>(`${API_ENDPOINTS.GET_MIN_SERVICE_RULES}`,);
        yield put(saveMinServiceRulesList(response.resultData));
        yield put(saveMinServiceRuleDataLoading(false));
    } catch (err) {
    }
}


export function* deleteMinServiceRuleSaga() {
    yield takeLatest(TYPES.DELETE_MIN_SERVICE_RULE, deleteMinServiceRuleWorker);
}

function* deleteMinServiceRuleWorker(param: any) {
    try {
        yield put(saveMinServiceRuleDataLoading(true));
        const response: AppSuccessResponse = yield yield customAxios.delete<any, AppSuccessResponse>(`${API_ENDPOINTS.DELETE_MIN_SERVICE_RULE}${param['payload']}`);
        yield put((getMinServiceRuleList()))
    } catch (err) {
    }
}


export function* insertMinServiceRuleSaga() {
    yield takeLatest(TYPES.INSERT_MIN_SERVICE_RULE, insertMinServiceWorker);
}

function* insertMinServiceWorker(param: any) {
    try {
        yield put(saveMinServiceRuleDataLoading(true));
        const response: AppSuccessResponse = yield yield customAxios.put<any, AppSuccessResponse>(`${API_ENDPOINTS.CREATE_MIN_SERVICE_RULE}`,param['payload']);
        yield put(getMinServiceRuleList())
    } catch (err) {
    }
}


export function* updateMinServiceRuleSaga() {
    yield takeLatest(TYPES.UPDATE_MIN_SERVICE_RULE, updateMinServiceWorker);
}

function* updateMinServiceWorker(param: any) {
    try {
        yield put(saveMinServiceRuleDataLoading(true));
        const response: AppSuccessResponse = yield yield customAxios.post<any, AppSuccessResponse>(`${API_ENDPOINTS.UPDATE_MIN_SERVICE_RULE}`,param['payload']);
        yield put(getMinServiceRuleList())
    } catch (err) {
    }

}
