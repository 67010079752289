import { SAVE_SERVICE_RULE_DATA_LOADING, SAVE_SERVICE_RULE_LIST ,SAVE_MIN_SERVICE_RULE_LIST,SAVE_MIN_SERVICE_RULE_DATA_LOADING} from "../actions/types";




const initialState = {
    serviceRulesList: [],
    isDataLoading:false,
    serviceMinRulesList:[],
    isMinDataLoading:false
  };


export const ServiceRuleReducer = (state = initialState, action: any) => {
    const oldState = { ...state };
    const { type, payload } = action;
  
    switch (type) {
      case SAVE_SERVICE_RULE_LIST:
        return {
          ...oldState,
          serviceRulesList: payload,
        };

        case SAVE_SERVICE_RULE_DATA_LOADING:
            return {
              ...oldState,
              isDataLoading: payload,
            };
        
            case SAVE_MIN_SERVICE_RULE_LIST:
              return {
                ...oldState,
                serviceMinRulesList: payload,
              };
      
              case SAVE_MIN_SERVICE_RULE_DATA_LOADING:
                  return {
                    ...oldState,
                    isMinDataLoading: payload,
                  };
    }
    return oldState;
  };