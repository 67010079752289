import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

const ClockInOutConfirmModal = (props: ClockInOutConfirmModalProps) => {
  const {
    title,
    message,
    onClose,
    onConfirm,
  } = props;


  return (
    <Dialog
      className='xs-modal '
      onClose={onClose} title={title}>
      <p style={{ margin: "25px" }} >
        {message}
      </p>
      <DialogActionsBar>

        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base textWhite submitButon"
          onClick={onConfirm}
        >
          Yes
        </button>

        <button
          className=" k-button-md k-rounded-md k-button-solid k-button-solid-base cancelBtn sealBtn mr-1"
          onClick={onClose}
        >
          No
        </button>

      </DialogActionsBar>
    </Dialog>
  )
}

export default ClockInOutConfirmModal

export interface ClockInOutConfirmModalProps {
  title: string
  message: String
  onClose?: () => void;
  onConfirm?: () => void;
}