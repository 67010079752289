import React, { ComponentState, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../control-components/loader/loader";
import MapComponent from "./mapComponent";
import { Navigate, useNavigate } from "react-router";
import { GET_CLIENTS_FOR_CASELOAD_MAP } from "../../../redux/actions/types";

const SELECTED_FIELD = "selected";

const MapHeader = ({ }) => {
  const [loading, setLoading] = useState(false);
  const state: ComponentState = useSelector(states => {
    return states;
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { clientsForCaseloadMap } = state['ClientReducer']

  const apiKey = 'AIzaSyCkqXhLIAeId9vusakyODOkzd9uYqaqVPA';

  useEffect(() => {
    // Fetch data when the component mounts
    if (!clientsForCaseloadMap || clientsForCaseloadMap.length === 0) {
      setLoading(true);
      dispatch({
        type: GET_CLIENTS_FOR_CASELOAD_MAP,
        payload: {'isActive': true},
      });
      setLoading(false);
    }
  }, [dispatch, clientsForCaseloadMap]);

  return (
    <>
     <div className="d-flex flex-wrap">
      {loading === true && <Loader />}
      <div className="col-md-12 col-lg-12 ">
        <div className="px-2">
          <button
              type="button"
              value="BACK"
              onClick={() => navigate(-1)}
              className="border-0 bg-transparent arrow-rotate mb-3"
              style={{ padding: "0", margin: "0" }}
            >
              <i className="k-icon k-i-sort-asc-sm"></i> Back
            </button>
          <div className=" ">
          <MapComponent
            apiKey={apiKey}
            clientsForCaseloadMap={clientsForCaseloadMap}
          />
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default MapHeader;
