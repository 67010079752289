
import React, { Component, useEffect, useState } from "react";
import { Error } from "@progress/kendo-react-labels";
import { DatePicker, DatePickerChangeEvent, MultiViewCalendar } from "@progress/kendo-react-dateinputs";

export interface DatePickerProps {
  onChange?: (newValue: DatePickerChangeEvent) => void
  error?: any,
  name?: string,
  title?: string,
  value?: Date,
  required?: boolean,
  validityStyles?: any,
  label?: string,
  placeholder?: string,
  max?: Date,
  min?: Date,
  disabled?: boolean,
  ref?: any,
  format?: any
}

function DatePickerKendoRct(props: DatePickerProps) {

  const {
    onChange,
    error,
    name,
    title,
    value,
    required,
    validityStyles,
    label,
    placeholder,
    max,
    min,
    disabled,
    ref,
    format
  } = props;

  return (
    <div>
    <label>{label}</label>
    <DatePicker
      calendar={CustomCalendar}
      onChange={onChange}
      format={"M/d/yyyy"}
      value={value ? value : null}
      name={name}
      size={"medium"}
      label = {""}
      title={title}
      required={required}
      validityStyles={validityStyles}
      max={max}
      placeholder={placeholder}
      min={min}
      disabled={disabled}
      ref={ref}
    />
      
      {error && <Error>{error}</Error>}
    </div>
  );

}

export default DatePickerKendoRct;
export const CustomCalendar = (props) => {
  return <MultiViewCalendar {...props} views={1} />;
};


