import React, { useState, useEffect } from 'react';
import Video from 'twilio-video';
import Participant from './participant';
import { useSelector } from 'react-redux';
import ClipboardCopy from "../clip-board-copy";
import SendLink from './send-link-button';
import { isMobile } from 'react-device-detect';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

const Room = ({ roomName, token, handleLogout, createdBy, setConnecting, joinUrl, clientDetails}) => {
  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);
  const staffId = useSelector(state => state.loggedIn?.staffId);
  const [isShow, setIsShow] = useState(false);
  const [isMute, setIsMute] = useState(false);
  const [staffInfo, setStaffInfo] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [sessionTime, setSessionTime] = useState(60 * 60 * 1000);


useEffect(() => {
  const timer = setTimeout(() => {
    if (sessionTime > 0) {
      setSessionTime((prev) => prev - 1000);

      if (sessionTime === 3 * 60 * 1000) {
        setShowPopup(true);
      }
    }
  }, 1000);

  return () => clearTimeout(timer);
}, [sessionTime]);


 const handleExtendSession = () => {
     setShowPopup(false);
     setSessionTime(60 * 60 * 1000);
   };

   const handleCancelPopup = () => {
     setShowPopup(false);
     setSessionTime(60 * 60 * 1000);
   };


  useEffect(() => {
    const participantConnected = (participant) => {
      setParticipants(prevParticipants => [...prevParticipants, participant]);
    };

    const participantDisconnected = (participant) => {
      let data = JSON.parse(participant.identity)
      if (createdBy === data.userId) {
        handleLogout();
      }
      setParticipants(prevParticipants =>
        prevParticipants.filter(p => p !== participant)
      );
    };

    const joinConfig = isMobile ? { height: 480, frameRate: 24, width: 640 } : { height: 720, frameRate: 24, width: 1280 }

    Video.connect(token, {
      name: roomName,
      video: joinConfig,
      audio: true,
      bandwidthProfile: {
        video: {
          mode: 'collaboration',
          maxTracks: 10,
          dominantSpeakerPriority: joinUrl?'standard':'high', //FOR CLINICIAN: dominantSpeakerPriority: 'high'
          renderDimensions: {
            high: { height: 1080, width: 1920 },
            standard: { height: 720, width: 1280 },
            low: { height: 176, width: 144 }
          }
        }
      },
      dominantSpeaker: true,
      preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
      networkQuality: { local: 1, remote: 1 }
    }).then(room => {
      setRoom(room);
      let staffInfoUpdate = JSON.parse(room?.localParticipant?.identity)
      setStaffInfo(staffInfoUpdate)
      room.on('participantConnected', participantConnected);
      room.on('participantDisconnected', participantDisconnected);
      room.participants.forEach(participantConnected);
      setConnecting(false)
    });

    return () => {
      setRoom(currentRoom => {
        if (currentRoom && currentRoom.localParticipant?.state === 'connected') {
          currentRoom.localParticipant?.tracks.forEach(function (trackPublication) {
            trackPublication.track.stop();
          });
          currentRoom.disconnect();
          return null;
        } else {
          return currentRoom;
        }
      });
    };

  }, [roomName, token]);



  const remoteParticipants = participants.map(participant => (
    <Participant key={participant.sid} participant={participant} allParticipants={participants}
      handleLogout={handleLogout}
    />
  ));

  const handleShow = () => {
    setIsShow(true)
    room.localParticipant?.videoTracks.forEach((publication) => {
      publication.track.disable();
    });
  }

  const handleHide = () => {
    setIsShow(false)
    room.localParticipant?.videoTracks.forEach((publication) => {
      publication.track.enable();
    });
  }


  const handleUnmute = () => {
    setIsMute(true)
    room.localParticipant?.audioTracks.forEach((publication) => {
      publication.track.disable();
    });
  }

  const handleMute = () => {
    setIsMute(false)
    room.localParticipant?.audioTracks.forEach((publication) => {
      publication.track.enable();
    });
  }

  return (

    <div className='video-call'>
      {showPopup && (

        <Dialog className="xs-modal width-given radiusZero" title={"Session Expire Time"} onClose={handleCancelPopup}>
        <b>5 minutes remaining.</b> Do you want to continue?
        <DialogActionsBar>
          <button
            className="submitButon m-0"oi
            onClick={handleExtendSession}
          >
            {" "}
            Yes
          </button>
          <button
            className="cancelBtn m-0"
            onClick={handleCancelPopup}
          >
            cancel
          </button>
        </DialogActionsBar>
      </Dialog>
    )}
      <div className={"video-phone-btn"}   >
        {
          <>
            <div>
              {
                staffId == staffInfo?.userId &&
                <SendLink clientDetails={clientDetails} roomName={roomName} ></SendLink>
              }
            </div>
            <div>
              {
                staffId == staffInfo?.userId &&
                <ClipboardCopy copyText={encodeURI(joinUrl)} ></ClipboardCopy>
              }
            </div>
            <div className="video-cus ml-1">
              {
                isShow ?

                  <i onClick={handleHide} className="fa fa-video-slash" aria-hidden="true"></i> :
                  <i onClick={handleShow} className="fa fa-video-camera" aria-hidden="true"></i>

              }
            </div>
            <div className="phone-cus ml-2">
              {
                isMute ? <i onClick={handleMute} className="fa fa-microphone-slash" aria-hidden="true"></i> :
                  <i onClick={handleUnmute} className="fa fa-microphone" aria-hidden="true"></i>

              }
            </div>
            <div className='ml-2'>
              <button onClick={handleLogout} className="end-call-mainbtn"><i className="fa fa-phone end-call-btn" ></i> Leave</button>
            </div>

          </>
        }
      </div>
      <div className="column-room-one">
        <div className="grid-column-one">
          {room ? (
            <Participant
              key={room.localParticipant?.sid}
              participant={room?.localParticipant}
              allParticipants={participants}
              handleLogout={handleLogout}
              joinUrl={joinUrl}
              room={room}
            />
          ) : (
            ''
          )}
          {remoteParticipants}
        </div>
        {/* {
        room &&
        <div className='d-flex justify-content-center py-3 mt-2 gap-5 custom-button-style position-relative'>
          <button onClick={handleLogout} className="end-call-mainbtn"><i className="fa fa-phone end-call-btn" ></i></button>
        </div>
      } */}
      </div>



    </div>

  );


};

export default Room;