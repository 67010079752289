import React, { useRef } from "react";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import { Button } from "@progress/kendo-react-buttons";
import moment from "moment";

export interface ComplianceExcelList {
  Name?: string;
  Sites?: string;
  Payer?: string;
  Status?: string;
  [key: string]: any;
}

const ComplianceExportToExcel = ({ cpReportList }: { cpReportList: any[] }) => {
  const _exporter = useRef<ExcelExport | null>(null);
  const updatedReportList = cpReportList.map((item) => {
    return {
      ...item,
      complianceReport: item?.complianceReport?.map((report) => {
        return {
          ...report,
          complianceName: report?.complianceName?.replace(/\./g, ""),
        };
      }),
    };
  });

  const exportExcel = () => {
    if (_exporter.current) {
      save(_exporter.current);
    }
  };

  const save = (component: ExcelExport) => {
    const options = component.workbookOptions();
    const rows = options.sheets![0].rows;

    rows?.forEach((row) => {
      if (row.type === "data") {
        row.cells?.forEach((cell) => {
          cell.background = "#FFFFFF";
          cell.color = "#000000";

          cell.borderBottom = { color: "#000000", size: 1 };
          cell.borderTop = { color: "#000000", size: 1 };
          cell.borderLeft = { color: "#000000", size: 1 };
          cell.borderRight = { color: "#000000", size: 1 };

          const cellValue = cell.value;
          if (
            typeof cellValue === "string" &&
            cellValue.includes("Next Due on")
          ) {
            cell.background = "#28a745";
            cell.color = "#FFFFFF";
          } else if (
            typeof cellValue === "string" &&
            cellValue.includes("Completed on")
          ) {
            cell.background = "#28a745";
            cell.color = "#FFFFFF";
          } else if (cellValue === "NA") {
            cell.background = "#28a745";
            cell.color = "#FFFFFF";
          } else if (
            typeof cellValue === "string" &&
            cellValue.includes("Pending (Due on")
          ) {
            cell.background = "#ffc107";
            cell.color = "#000000";
          }
        });
      }
    });

    component.save(options);
  };

  const preprocessData = (originalList: any[]): ComplianceExcelList[] => {
    const currentComplianceReport = (currentCp) => {
      if (currentCp?.isCompleted) {
        if (
          moment(currentCp?.complianceNextDueDate).format("M/D/YYYY") >
          moment(currentCp?.completedDate).format("M/D/YYYY")
        ) {
          return `Next Due on ${moment(currentCp?.complianceNextDueDate).format(
            "M/D/YYYY"
          )}`;
        }
        return `Completed on ${moment(currentCp?.completedDate).format(
          "M/D/YYYY"
        )}`;
      }

      if (!currentCp?.isApplicable) {
        return `NA`;
      }

      if (currentCp?.isRestrictive) {
        return `Pending (Due on ${moment(
          currentCp?.complianceNextDueDate
        ).format("M/D/YYYY")})`;
      }

      return `Pending (Due on ${moment(currentCp?.complianceNextDueDate).format(
        "M/D/YYYY"
      )})`;
    };

    return originalList.map((item) => {
      const complianceData = item.complianceReport?.reduce(
        (acc: any, compliance: any) => {
          acc[compliance.complianceName] =
            currentComplianceReport(compliance) || "";
          return acc;
        },
        {}
      );

      return {
        Name: `${item?.lName || ""}, ${item?.fName || ""} (${
          item?.age || ""
        })`.trim(),
        Sites: item?.siteName || "",
        Payer: item?.payerName || "",
        Status: item?.status || "",
        ...complianceData,
      };
    });
  };

  const complianceExportToExcelList = preprocessData(updatedReportList);

  const columnHeaders = Array.from(
    new Set(
      updatedReportList
        .flatMap((item) => item.complianceReport || [])
        .map((compliance) => compliance.complianceName || "")
    )
  );

  return (
    <div className="ml-2 blueTheme">
      <Button
        onClick={exportExcel}
        className="submitButon"
        style={{ height: "35px" }}
      >
        <i className="fa-regular fa-file-excel"></i> Export to Excel
      </Button>
      <ExcelExport
        data={complianceExportToExcelList}
        fileName="ComplianceReport.xlsx"
        ref={_exporter}
      >
        <ExcelExportColumn field="Name" title="Name" width={150} />
        <ExcelExportColumn field="Sites" title="Sites" width={150} />
        <ExcelExportColumn field="Payer" title="Payer" width={150} />
        <ExcelExportColumn field="Status" title="Status" width={150} />
        {columnHeaders.map((complianceName, index) => (
          <ExcelExportColumn
            key={index}
            field={complianceName}
            title={complianceName}
            width={150}
          />
        ))}
      </ExcelExport>
    </div>
  );
};

export default ComplianceExportToExcel;
