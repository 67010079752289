
export const LOGIN_ACTION = 'LOGIN_ACTION';
export const LOADER = 'LOADER';
export const ROLE_MODE = 'ROLE_MODE';
export const STAFF_SETTINGS = 'STAFF_SETTINGS';

export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const CLINIC_ID = "CLINIC_ID";
export const CLIENT_ID = "CLIENT_ID";
export const CLIENT_INSURANCE_ID = "CLIENT_INSURANCE_ID";
export const GET_CLIENT_FLAGS = "GET_CLIENT_FLAGS";
export const SELECTED_CLIENT_ID = "SELECTED_CLIENT_ID";
export const SELECTED_CLIENT_FILTER = "SELECTED_CLIENT_FILTER";

export const REMOVE_CLIENT_FILTER = "REMOVE_CLIENT_FILTER";
export const SELECTED_STAFF_ID = "SELECTED_STAFF_ID";
export const SELECTED_SERVICE_FILTER = "SELECTED_SERVICE_FILTER";
export const GET_STAFF = "GET_STAFF";
export const GET_MARITIALS_STATUS = "GET_MARITIALS_STATUS";
export const GET_GENDER = "GET_GENDER";

export const SELECTED_SERVICE_ID = "SELECTED_SERVICE_ID";
export const STAFF_LOGIN_DETAIL = "STAFF_LOGIN_DETAIL";
export const STAFF_ONLINE_STATUS = "STAFF_ONLINE_STATUS";

export const IMMUNIZATION_ID = "IMMUNIZATION_ID";

export const SELECTED_DRAWER_ROUTE = "SELECTED_DRAWER_ROUTE";
export const SELECTED_HEADER_MENU = "SELECTED_HEADER_MENU";
export const IS_GLOBAL_SEARCH = "IS_GLOBAL_SEARCH";
export const GET_ROLE = "GET_ROLE";
export const SAVE_ROLE = "SAVE_ROLE";
export const GET_CLIENT_DETAILS = "GET_CLIENT_DETAILS";
export const AGE_CAlCULATION = "AGE_CAlCULATION";
export const GET_CLIENT_PROFILE_IMG = "GET_CLIENT_PROFILE_IMG";
export const GET_STAFF_DETAILS = "GET_STAFF_DETAILS";
export const GET_STAFF_PROFILE_IMG = "GET_STAFF_PROFILE_IMG";
export const GET_CLIENT_INSURANCE = "GET_CLIENT_INSURANCE";
export const GET_CLIENT_PRIVATE_PAY_STATUS = "GET_CLIENT_PRIVATE_PAY_STATUS";
export const USER_CAN_ACCESS = "USER_CAN_ACCESS";
export const GET_PRIMARY_CARE_PHYSICIAN = "GET_PRIMARY_CARE_PHYSICIAN";
export const GET_PEDIATRICIAN = "GET_PEDIATRICIAN";
export const GET_ROLE_PERMISSIONS = "GET_ROLE_PERMISSIONS";

export const DOC_TEMPLATE_INFO = "DOC_TEMPLATE_INFO";

export const SITE_ID = "SITE_ID";
export const ALL_CLIENT_AVAILABLE = "ALL_CLIENT_AVAILABLE";
export const ALL_STAFF_AVAILABLE = "ALL_STAFF_AVAILABLE";
export const SITE_VALUE = "SITE_VALUE";
export const GET_DOCUMENT_FILTER = "GET_DOCUMENT_FILTER";
export const GET_CLIENT_DOCUMENT_FILTER = "GET_CLIENT_DOCUMENT_FILTER";
export const GET_DOC_BILLING_FILTER = "GET_DOC_BILLING_FILTER";
export const GET_BILL_TRANSACTION_FILTER = "GET_BILL_TRANSACTION_FILTER";
export const GET_CLINIC_DETAILS_BY_ID = "GET_CLINIC_DETAILS_BY_ID";
export const SELECTED_APP_SITE = "SELECTED_APP_SITE";
export const GET_BILL_REMITTANCE = "GET_BILL_REMITTANCE"
export const SET_CLIENT_LIST_PAGE_NUMBER = "SET_CLIENT_LIST_PAGE_NUMBER"
export const JOIN_URL = "JOIN_URL"
export const SELECTED_CLIENT_NAME = "SELECTED_CLIENT_NAME"
export const SELECTED_STAFF_FILTER = "SELECTED_STAFF_FILTER"
export const GET_BILL_CLAIM_VIEW = "GET_BILL_CLAIM_VIEW"




//DOCUMENTS NEW ACTIONS--
export const GET_DRAFT_COUNT = "GET_DRAFT_COUNT";
export const SAVE_DRAFT_COUNT = "SAVE_DRAFT_COUNT";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const REVERT_DOCUMENT = "REVERT_DOCUMENT";
export const SAVE_AND_REFRESH = "SAVE_AND_REFRESH";
export const GET_DOC_LIST = "GET_DOC_LIST";
export const SAVE_DOC_LIST = "SAVE_DOC_LIST";
export const GET_CLINIC_LOGO = "GET_CLINIC_LOGO";
export const SAVE_CLINIC_LOGO_BYTES = "SAVE_CLINIC_LOGO_BYTES";
export const SAVE_CLINIC_LOGO_URL = "SAVE_CLINIC_LOGO_URL";

//CHART NEW ACTIONS

export const GET_STAFF_MOST_DOCUMENTS = "GET_STAFF_MOST_DOCUMENTS";
export const GET_CLIENT_MOST_DOCUMENTS = "GET_CLIENT_MOST_DOCUMENTS";
export const GET_BILLING_STATUS_METRICS = "GET_BILLING_STATUS_METRICS"
export const SAVE_STAFF_MOST_DOCUMENTS = "SAVE_STAFF_MOST_DOCUMENTS"
export const SAVE_CLIENT_MOST_DOCUMENTS = "SAVE_CLIENT_MOST_DOCUMENTS"
export const SAVE_BILLING_STATUS_METRICS = "SAVE_BILLING_STATUS_METRICS"
export const GET_CLIENT_LIST = "GET_CLIENT_LIST";
export const GET_STAFF_LIST = "GET_STAFF_LIST";
export const GET_STAFF_LIST_ACTIVE_INACTIVE = "GET_STAFF_LIST_ACTIVE_INACTIVE";

export const GET_SERVICE_LIST = "GET_SERVICE_LIST";
export const GET_SITE_LIST = "GET_SITE_LIST";
export const SAVE_CLIENT_LIST = "SAVE_CLIENT_LIST";
export const SAVE_STAFF_LIST = "SAVE_STAFF_LIST";
export const SAVE_SERVICE_LIST = "SAVE_SERVICE_LIST";
export const SAVE_SITE_LIST = "SAVE_SITE_LIST";
export const GET_CLIENT_BILLING_CLAIMS = "GET_CLIENT_BILLING_CLAIMS";
export const SAVE_CLIENT_BILLING_CLAIMS = "SAVE_CLIENT_BILLING_CLAIMS";
export const GET_DOCUMENT_BY_ID = "GET_DOCUMENT_BY_ID";
export const SAVE_DOCUMENT_BY_ID = "SAVE_DOCUMENT_BY_ID";
export const GET_CLAIM_SERVICE_LINE = "GET_CLAIM_SERVICE_LINE";
export const SAVE_CLAIM_SERVICE_LINE = "SAVE_CLAIM_SERVICE_LINE";


// Clinic Client Compliance
export const CREATE_CLIENT_COMPLIANCE = "CREATE_CLIENT_COMPLIANCE";
export const UPDATE_CLIENT_COMPLIANCE = "UPDATE_CLIENT_COMPLIANCE";
export const DELETE_CLIENT_COMPLIANCE = "DELETE_CLIENT_COMPLIANCE";
export const GET_CLIENT_COMPLIANCE_FOR_CLINIC = "GET_CLIENT_COMPLIANCE_FOR_CLINIC";
export const SAVE_CLIENT_COMPLIANCE_FOR_CLINIC = "SAVE_CLIENT_COMPLIANCE_FOR_CLINIC";
export const GET_CLINIC_SERVICES = "GET_CLINIC_SERVICES";
export const SAVE_CLINIC_SERVICES = "SAVE_CLINIC_SERVICES";
export const GET_DOCUMENT_TEMPLATE = "GET_DOCUMENT_TEMPLATE";
export const SAVE_DOCUMENT_TEMPLATE = "SAVE_DOCUMENT_TEMPLATE";
export const GET_CLIENT_COMPLIANCE = "GET_CLIENT_COMPLIANCE";
export const SAVE_CLIENT_COMPLIANCE = "SAVE_CLIENT_COMPLIANCE";
export const GET_CLIENT_COMPLIANCE_BY_ID = "GET_CLIENT_COMPLIANCE_BY_ID";
export const SAVE_CLIENT_COMPLIANCE_BY_ID = "SAVE_CLIENT_COMPLIANCE_BY_ID";
export const GET_DOC_BIILING_HISTORY = "GET_DOC_BIILING_HISTORY";
export const SAVE_DOC_BIILING_HISTORY = "SAVE_DOC_BIILING_HISTORY";
export const SELECT_BATCH_ID = "SELECT_BATCH_ID";
export const SELECT_PAYER_CLAIM_ID = "SELECT_PAYER_CLAIM_ID"
export const SELECT_EOB_ID = "SELECT_EOB_ID";


// ------------------------------------Client-----------------------
export const GET_CLIENT_COMPLIANCE_LIST = "GET_CLIENT_COMPLIANCE_LIST";
export const SAVE_CLIENT_COMPLIANCE_LIST = "SAVE_CLIENT_COMPLIANCE_LIST";
export const COMPLETE_COMPLIANCE = "COMPLETE_COMPLIANCE";
export const DELETE_MANUALLY_COMPLETED_COMPLIANCE = "DELETE_MANUALLY_COMPLETED_COMPLIANCE"
export const GET_ACTIVE_STAFF_SEATS = "GET_ACTIVE_STAFF_SEATS";
export const SAVE_ACTIVE_STAFF_SEATS = "SAVE_ACTIVE_STAFF_SEATS"
export const CHECK_COMPLIANCE = "CHECK_COMPLIANCE"
export const GET_CLINIC_FLAG = "GET_CLINIC_FLAG"
export const SAVE_CLINIC_FLAG = "SAVE_CLINIC_FLAG"
export const ASSIGN_FLAG_TO_CLIENT = "ASSIGN_FLAG_TO_CLIENT"
export const SAVE_ASSIGN_FLAG_TO_CLIENT = "SAVE_ASSIGN_FLAG_TO_CLIENT"

export const GET_CLIENT_STATUS = "GET_CLIENT_STATUS"
export const SAVE_CLIENT_STATUS = "SAVE_CLIENT_STATUS"
export const SAVE_SELECTED_CLIENT_STATUS = "SAVE_SELECTED_CLIENT_STATUS"
export const CLIENT_STATUS_LOADING_STATE = "CLIENT_STATUS_LOADING_STATE"
export const SAVE_SELECTED_CLIENT_ELIGIBILITY = "SAVE_SELECTED_CLIENT_ELIGIBILITY"
export const GET_UNREAD_COUNT = "GET_UNREAD_COUNT"
export const SAVE_UNREAD_COUNT = "SAVE_UNREAD_COUNT"
export const SAVE_ADD_DOC_DIAGNOSIS = "SAVE_ADD_DOC_DIAGNOSIS"
export const SAVE_ADD_DOC_GOALS = "SAVE_ADD_DOC_GOALS"
export const SAVE_ADD_DOC_PROFILE_INFO = "SAVE_ADD_DOC_PROFILE_INFO"
export const SET_DOCUMENT_LIST_PAGE_NUMBER = "SET_DOCUMENT_LIST_PAGE_NUMBER"
export const SAVE_DOCUMENT_PAGE_SIZE = "SAVE_DOCUMENT_PAGE_SIZE"
export const GET_MULTIPLE_CLIENTS = "GET_MULTIPLE_CLIENTS"
export const SAVE_MULTIPLE_CLIENTS = "SAVE_MULTIPLE_CLIENTS"
export const UNREAD_MESSAGE_COUNT = "UNREAD_MESSAGE_COUNT"
export const SAVE_SWITCH_VALUE = "SAVE_SWITCH_VALUE"
export const SAVE_SWITCH_STAFF_VALUE = "SAVE_SWITCH_STAFF_VALUE"

export const GET_BILLING_TRANS_ITEM_BY_CLAIMID = "GET_BILLING_TRANS_ITEM_BY_CLAIMID"
export const SAVE_BILLING_TRANS_ITEM_BY_CLAIMID = "SAVE_BILLING_TRANS_ITEM_BY_CLAIMID"
export const GET_CLINIC_DEFAULT_GOALS = "GET_CLINIC_DEFAULT_GOALS"
export const SAVE_CLINIC_DEFAULT_GOALS = "SAVE_CLINIC_DEFAULT_GOALS"
export const DELETE_CLINIC_DEFAULT_GOALS = "DELETE_CLINIC_DEFAULT_GOALS"
export const ASSIGN_DEFAULT_GOALS_TO_CLIENT = "ASSIGN_DEFAULT_GOALS_TO_CLIENT"
export const SAVE_ASSIGN_DEFAULT_GOALS_TO_CLIENT = "SAVE_ASSIGN_DEFAULT_GOALS_TO_CLIENT"
export const SAVE_GOAL_BY_ID = "SAVE_GOAL_BY_ID"
export const GET_GOAL_BY_ID = "GET_GOAL_BY_ID"
export const GET_CLIENT_COUNT = "GET_CLIENT_COUNT"
export const SAVE_CLIENT_COUNT = "SAVE_CLIENT_COUNT"

export const GET_SERVICE_SETS = "GET_SERVICE_SETS"
export const SAVE_SERVICE_SETS = "SAVE_SERVICE_SETS"
export const DELETE_SERVICE_SETS = "DELETE_SERVICE_SETS"
export const INSERT_SERVICE_SETS = "INSERT_SERVICE_SETS"
export const UPDATE_SERVICE_SETS = "UPDATE_SERVICE_SETS"
export const ASSIGN_SET_TO_STAFF = "ASSIGN_SET_TO_STAFF"
export const GET_AVAILABLE_SERVICE_LIST = "GET_AVAILABLE_SERVICE_LIST"
export const SAVE_AVAILABLE_SERVICE_LIST = "SAVE_AVAILABLE_SERVICE_LIST"
export const GET_CLIENT_DIAGNOSIS_COUNT = "GET_CLIENT_DIAGNOSIS_COUNT"
export const SAVE_CLIENT_DIAGNOSIS_COUNT = "SAVE_CLIENT_DIAGNOSIS_COUNT"
export const GET_SERVICE_UNITS_RENDER = "GET_SERVICE_UNITS_RENDER"
export const SAVE_SERVICE_UNITS_RENDER = "SAVE_SERVICE_UNITS_RENDER"
export const GET_BILLING_COUNTS = "GET_BILLING_COUNTS"
export const SAVE_BILLING_COUNTS = "SAVE_BILLING_COUNTS"
export const GET_SERVICE_DDL_BY_CLINIC_ID = "GET_SERVICE_DDL_BY_CLINIC_ID"
export const SAVE_SERVICE_DDL_BY_CLINIC_ID = "SAVE_SERVICE_DDL_BY_CLINIC_ID"
export const GET_SERVICE_SET_DDL = "GET_SERVICE_SET_DDL"
export const SAVE_SERVICE_SET_DDL = "SAVE_SERVICE_SET_DDL"
export const SAVE_ASSIGN_TO_STAFF_RESPONSE = "SAVE_ASSIGN_TO_STAFF_RESPONSE"
export const SAVE_BILLING_DATE = "SAVE_BILLING_DATE"
export const SAVE_CLIENT_TAB_ADMIN_BOARD_FILTERS = "SAVE_CLIENT_TAB_ADMIN_BOARD_FILTERS"

export const SAVE_SELECTED_DOC_ID = "SAVE_SELECTED_DOC_ID"
export const GET_COMPLIANCE_REPORT = "GET_COMPLIANCE_REPORT"
export const SAVE_COMPLIANCE_REPORT = "SAVE_COMPLIANCE_REPORT"
export const GET_AUTH_SET = "GET_AUTH_SET"
export const SAVE_AUTH_SET = "SAVE_AUTH_SET"
export const DELETE_AUTH_SET = "DELETE_AUTH_SET"
export const CREATE_NEW_AUTH_SET = "CREATE_NEW_AUTH_SET"
export const UPDATE_AUTH_SET = "UPDATE_AUTH_SET"
export const GET_BILLING_ACTION = "GET_BILLING_ACTION"
export const SAVE_BILLING_ACTION = "SAVE_BILLING_ACTION"
export const LOADER_DOC_LIST = "LOADER_DOC_LIST"
export const SAVE_DEFAULT_GOALS_TEMPLATES = "SAVE_DEFAULT_GOALS_TEMPLATES"
export const UPDATE_DEFAULT_GOALS_TEMPLATES = "UPDATE_DEFAULT_GOALS_TEMPLATES"
export const DELETE_DEFAULT_GOALS_TEMPLATES = "DELETE_DEFAULT_GOALS_TEMPLATES"
export const GET_CLINIC_DEFAULT_GOALS_TEMPLATES = "GET_CLINIC_DEFAULT_GOALS_TEMPLATES"
export const SAVE_CLINIC_DEFAULT_GOALS_TEMPLATES = "SAVE_CLINIC_DEFAULT_GOALS_TEMPLATES"
export const GET_DEFAULT_GOALS_TEMPLATES_INFO = "GET_DEFAULT_GOALS_TEMPLATES_INFO"
export const SAVE_DEFAULT_GOALS_TEMPLATES_INFO = "SAVE_DEFAULT_GOALS_TEMPLATES_INFO"
export const ASSIGN_SET_TO_CLIENT = "ASSIGN_SET_TO_CLIENT"

// ---------------------------Clinc File
export const GET_CLINIC_DOCUMENT_S3_SIGNED_URL = "GET_CLINIC_DOCUMENT_S3_SIGNED_URL"
export const SAVE_CLINIC_DOCUMENT_S3_SIGNED_URL = "SAVE_CLINIC_DOCUMENT_S3_SIGNED_URL"
export const GET_CLINIC_STORED_DOCUMENT_BY_CLINIC_ID = "GET_CLINIC_STORED_DOCUMENT_BY_CLINIC_ID"
export const SAVE_CLINIC_STORED_DOCUMENT_BY_CLINIC_ID = "SAVE_CLINIC_STORED_DOCUMENT_BY_CLINIC_ID"
export const GET_CLINIC_S3_DOCUMENT_URL_BYID = "GET_CLINIC_S3_DOCUMENT_URL_BYID"
export const GET_CLINIC_DOCUMENT_BYID = "GET_CLINIC_DOCUMENT_BYID"
export const DELETE_CLINIC_DOCUMENT_TAG = "DELETE_CLINIC_DOCUMENT_TAG"
export const DELETE_CLINIC_DOCUMENT = "DELETE_CLINIC_DOCUMENT"
export const SAVE_CLINIC_DOCUMENT_BYID = "SAVE_CLINIC_DOCUMENT_BYID"
export const GET_CLINIC_TAGS = "GET_CLINIC_TAGS"
export const SAVE_CLINIC_TAGS = "SAVE_CLINIC_TAGS"
export const ASSIGN_CLINIC_TAGS = "ASSIGN_CLINIC_TAGS"
export const INSERT_CLINICIAN = "INSERT_CLINICIAN"
export const SAVE_INSERT_CLINICIAN = "SAVE_INSERT_CLINICIAN"
export const GET_CLINICIAN_LIST = "GET_CLINICIAN_LIST"
export const SAVE_CLINICIAN_LIST = "SAVE_CLINICIAN_LIST"
export const SAVE_AUTHORIZATION_FILTER = "SAVE_AUTHORIZATION_FILTER"
export const TASK_FILTER = "TASK_FILTER"
export const SAVE_TASK_FILTER = "SAVE_TASK_FILTER"
export const GET_TP_STAFF_TYPE_DLL = "GET_TP_STAFF_TYPE_DLL"
export const SAVE_TP_STAFF_TYPE_DLL = "SAVE_TP_STAFF_TYPE_DLL"
export const GET_EDI_BY_BATCH_ID = "GET_EDI_BY_BATCH_ID"
export const SAVE_EDI_BY_BATCH_ID = "SAVE_EDI_BY_BATCH_ID"
export const SAVE_CLIENT_AUTHORIZATION_FILTER = "SAVE_CLIENT_AUTHORIZATION_FILTER"


export const SAVE_REDIRECTED_DOC_ID = "SAVE_REDIRECTED_DOC_ID"
export const SET_APP_IS_IMPERSONATE_FLAG = "SET_APP_IS_IMPERSONATE_FLAG"
export const INSERT_REVIEW_GOAL = "INSERT_REVIEW_GOAL"
export const UPDATE_REVIEW_GOAL = "UPDATE_REVIEW_GOAL"
export const GET_REVIEW_GOAL_BY_GOAL_ID = "GET_REVIEW_GOAL_BY_GOAL_ID"
export const SAVE_REVIEW_GOAL_BY_GOAL_ID = "SAVE_REVIEW_GOAL_BY_GOAL_ID"
export const DELETE_REVIEW_GOAL = "DELETE_REVIEW_GOAL"
export const GET_STAFF_NOTIFICATION_SETTING = "GET_STAFF_NOTIFICATION_SETTING"
export const SAVE_STAFF_NOTIFICATION_SETTING = "SAVE_STAFF_NOTIFICATION_SETTING"
export const SAVE_STAFF_NOTIFICATION = "SAVE_STAFF_NOTIFICATION"
export const GET_STAFF_NEW_NOTIFICATION_SETTING = "GET_STAFF_NEW_NOTIFICATION_SETTING"
export const SAVE_STAFF_NEW_NOTIFICATION_SETTING = "SAVE_STAFF_NEW_NOTIFICATION_SETTING"
export const GET_STAFF_TASK_NOTIFICATION_SETTING = "GET_STAFF_TASK_NOTIFICATION_SETTING"
export const SAVE_STAFF_TASK_NOTIFICATION_SETTING = "SAVE_STAFF_TASK_NOTIFICATION_SETTING"
export const GET_STAFF_SCHEDULER_NOTIFICATION_SETTING = "GET_STAFF_SCHEDULER_NOTIFICATION_SETTING"
export const SAVE_STAFF_SCHEDULER_NOTIFICATION_SETTING = "SAVE_STAFF_SCHEDULER_NOTIFICATION_SETTING"
export const GET_CLINIC_NOTIFICATION_SETTING = "GET_CLINIC_NOTIFICATION_SETTING"
export const SAVE_CLINIC_NOTIFICATION_SETTING = "SAVE_CLINIC_NOTIFICATION_SETTING"
export const SAVE_CLINIC_NOTIFICATION_PAYLOAD = "SAVE_CLINIC_NOTIFICATION_PAYLOAD"
export const SAVE_CLINIC_NEW_AUTH_ALERT_SETTING = "SAVE_CLINIC_NEW_AUTH_ALERT_SETTING";
export const GET_CLINIC_NEW_AUTH_ALERT_SETTING = "GET_CLINIC_NEW_AUTH_ALERT_SETTING";

export const GET_AUTH_TRANSACTIONS = "GET_AUTH_TRANSACTIONS"
export const SAVE_AUTH_TRANSACTIONS = "SAVE_AUTH_TRANSACTIONS"
export const GET_CLOCK_STATUS = "GET_CLOCK_STATUS"
export const SAVE_CLOCK_STATUS = "SAVE_CLOCK_STATUS"
export const UPDATE_TIME_CLOCK = "UPDATE_TIME_CLOCK"
export const ASSIGN_NOTIFICATION_SETTING_TO_STAFF_ROLE = "ASSIGN_NOTIFICATION_SETTING_TO_STAFF_ROLE"

export const STORE_COMPLIANCE_REPORT = "STORE_COMPLIANCE_REPORT"
export const GET_STORE_COMPLIANCE_REPORT = "GET_STORE_COMPLIANCE_REPORT"
export const GET_DOCUMENT_SERVICES = "GET_DOCUMENT_SERVICES"
export const SAVE_DOCUMENT_SERVICES = "SAVE_DOCUMENT_SERVICES"
export const UPDATE_DOCUMENT_SERVICE = "UPDATE_DOCUMENT_SERVICE"
export const UPDATE_DOCUMENT_SERVICE_DATE = "UPDATE_DOCUMENT_SERVICE_DATE"
export const UPDATE_DOCUMENT_DIAGNOSIS = "UPDATE_DOCUMENT_DIAGNOSIS"
export const UPDATE_DOCUMENT_SITE_OF_SERVICE = "UPDATE_DOCUMENT_SITE_OF_SERVICE"
export const SAVE_EDIT_DOC_DATA = "SAVE_EDIT_DOC_DATA"
export const GET_PLACE_OF_SERVICE = "GET_PLACE_OF_SERVICE"
export const SAVE_PLACE_OF_SERVICE = "SAVE_PLACE_OF_SERVICE"
export const UPDATE_PLACE_OF_SERVICE_PAYLOAD = "UPDATE_PLACE_OF_SERVICE_PAYLOAD"
export const SAVE_TEMPLATE_SETTING = "SAVE_TEMPLATE_SETTING"
export const SUCCESS = "SUCCESS"
export const GET_RISK_DDL = "GET_RISK_DDL"
export const SAVE_RISK_DDL = "SAVE_RISK_DDL"
export const GET_RISK_FACTOR_DDL = "GET_RISK_FACTOR_DDL"
export const SAVE_RISK_FACTOR_DDL = "SAVE_RISK_FACTOR_DDL"
export const GET_RISK_PROTECTIVE_DDL = "GET_RISK_PROTECTIVE_DDL"
export const SAVE_RISK_PROTECTIVE_DDL = "SAVE_RISK_PROTECTIVE_DDL"



export const GET_STAFF_NOTIFICATION_MEDIUM_SETTING = "GET_STAFF_NOTIFICATION_MEDIUM_SETTING"
export const SAVE_STAFF_NOTIFICATION_MEDIUM_SETTING = "SAVE_STAFF_NOTIFICATION_MEDIUM_SETTING"
export const STAFF_NOTIFICATION_MEDIUM_SETTING = "STAFF_NOTIFICATION_MEDIUM_SETTING"
export const GET_AUTH_LIST = "GET_AUTH_LIST"
export const SAVE_ROLE_LIST = "SAVE_ROLE_LIST"
export const GET_ASSIGNED_NOTIFICATION_SETTINGS = "GET_ASSIGNED_NOTIFICATION_SETTINGS"
export const SAVE_ASSIGNED_ROLES_FOR_NOTIFICATION_SETTINGS = "SAVE_ASSIGNED_ROLES_FOR_NOTIFICATION_SETTINGS"
export const GET_TIME_CLOCK_BY_ID = "GET_TIME_CLOCK_BY_ID"
export const SAVE_TIME_CLOCK_BY_ID = "SAVE_TIME_CLOCK_BY_ID"
export const UPDATE_STAFF_TIME_CLOCK = "UPDATE_STAFF_TIME_CLOCK"
export const SAVE_UPDATE_STAFF_TIME_CLOCK_RESPONSE = "SAVE_UPDATE_STAFF_TIME_CLOCK_RESPONSE"





export const SET_EXPORT_PDF_COUNT = "SET_EXPORT_PDF_COUNT"
export const SET_PREP_PDF_COUNT = "SET_PREP_PDF_COUNT"

export const GET_CLIENT_BY_GENDER_COUNT = "GET_CLIENT_BY_GENDER_COUNT"
export const SAVE_CLIENT_BY_GENDER_COUNT = "SAVE_CLIENT_BY_GENDER_COUNT"

export const GET_LATEST_DOCUMENT_BY_TEMPLATE_ID = "GET_LATEST_DOCUMENT_BY_TEMPLATE_ID"
export const SAVE_LATEST_DOCUMENT_BY_TEMPLATE_ID = "SAVE_LATEST_DOCUMENT_BY_TEMPLATE_ID"
export const SAVE_DATA_FOR_AUTO_POPULATE_IN_TEMP = "SAVE_DATA_FOR_AUTO_POPULATE_IN_TEMP"

export const GET_CLINIC_TP_SERVICE_TYPES = "GET_CLINIC_TP_SERVICE_TYPES"
export const SAVE_CLINIC_TP_SERVICE_TYPES = "SAVE_CLINIC_TP_SERVICE_TYPES"
export const GET_CLINIC_TP_STAFF_TYPES = "GET_CLINIC_TP_STAFF_TYPES"
export const SAVE_CLINIC_TP_STAFF_TYPES = "SAVE_CLINIC_TP_STAFF_TYPES"

export const INSERT_OR_UPDATE_CLINIC_TP_SERVICE_TYPES = "INSERT_OR_UPDATE_CLINIC_TP_SERVICE_TYPES"
export const DELETE_CLINIC_TP_SERVICE_TYPES = "DELETE_CLINIC_TP_SERVICE_TYPES"
export const INSERT_OR_UPDATE_CLINIC_TP_STAFF_TYPES = "INSERT_OR_UPDATE_CLINIC_TP_STAFF_TYPES"
export const DELETE_CLINIC_TP_STAFF_TYPES = "DELETE_CLINIC_TP_STAFF_TYPES"




export const GET_BILLING_BY_PAYERS_METRICS = "GET_BILLING_BY_PAYERS_METRICS"
export const SAVE_BILLING_BY_PAYERS_METRICS = "SAVE_BILLING_BY_PAYERS_METRICS"
export const GET_CLIENT_PRIMARY_DX = "GET_CLIENT_PRIMARY_DX"

export const CHECK_DUPLICATE_CLIENTS = "CHECK_DUPLICATE_CLIENTS"
export const SAVE_DUPLICATE_CLIENTS_DATA="SAVE_DUPLICATE_CLIENTS_DATA"

export const LOCK_UNLOCK_DOCUMENTS = "LOCK_UNLOCK_DOCUMENTS"
export const APPROVE_DISAPPROVE_DOCUMENTS = "APPROVE_OR_DISAPPROVE_DOCUMENTS"
export const SAVE_APPROVE_DISAPPROVE_DOCUMENTS = "SAVE_APPROVE_DISAPPROVE_DOCUMENTS"

export const GET_TIME_CLOCK_ARCHIVE_BY_ID = "GET_TIME_CLOCK_ARCHIVE_BY_ID"
export const SAVE_TIME_CLOCK_ARCHIVE = "SAVE_TIME_CLOCK_ARCHIVE"

export const ASSIGN_CERTIFICATE_TO_STAFFS = "ASSIGN_CERTIFICATE_TO_STAFFS"


export const GET_STAFF_DOC_TEMPLATES = "GET_STAFF_DOC_TEMPLATES"
export const SAVE_STAFF_DOC_TEMPLATES = "SAVE_STAFF_DOC_TEMPLATES"
export const GET_STAFF_DOC_STAFF_LIST = "GET_STAFF_DOC_STAFF_LIST"
export const SAVE_STAFF_DOC_STAFF_LIST = "SAVE_STAFF_DOC_STAFF_LIST"
export const GET_STAFF_DOC_TEMPLATE_SETTING = "GET_TEMPLATE_SETTING"
export const SAVE_STAFF_DOC_TEMPLATE_SETTING = "SAVE_STAFF_DOC_TEMPLATE_SETTING"
export const GET_STAFF_DOC_SETTING = "GET_STAFF_DOC_SETTING"
export const SAVE_STAFF_DOC_SETTING = "SAVE_STAFF_DOC_SETTING"
export const GET_STAFF_DOC_TEMPLATE_DETAIL = "GET_STAFF_DOC_TEMPLATE_DETAIL"
export const SAVE_STAFF_DOC_TEMPLATE_DETAIL = "SAVE_STAFF_DOC_TEMPLATE_DETAIL"
export const GET_STAFF_GOALS = "GET_STAFF_GOALS"
export const SAVE_STAFF_GOALS = "SAVE_STAFF_GOALS"
export const GET_SKILL_SET = "GET_SKILL_SET"
export const SAVE_SKILL_SET = "SAVE_SKILL_SET"
export const INSERT_STAFF_DOC = "INSERT_STAFF_DOC"
export const UPDATE_STAFF_DOC = "UPDATE_STAFF_DOC"
export const SAVE_STAFF_DOC = "SAVE_STAFF_DOC"
export const GET_STAFF_DOC_LISTING = "GET_STAFF_DOC_LISTING"
export const SAVE_STAFF_DOC_LISTING = "SAVE_STAFF_DOC_LISTING"
export const SAVE_STAFF_DOC_LOADING_STATE = "SAVE_STAFF_DOC_LOADING_STATE"
export const SAVE_STAFF_DOC_CREATE_LOADING_STATE = "SAVE_STAFF_DOC_CREATE_LOADING_STATE"
export const GET_STAFF_DOC_BY_ID = "GET_STAFF_DOC_BY_ID"
export const SAVE_STAFF_DOC_BY_ID = "SAVE_STAFF_DOC_BY_ID"
export const GET_STAFF_DOC_SIGNATURE = "GET_STAFF_DOC_SIGNATURE"
export const SAVE_STAFF_DOC_SIGNATURE = "SAVE_STAFF_DOC_SIGNATURE"
export const GET_STAFF_DOC_ATTACHMENTS = "GET_STAFF_DOC_ATTACHMENTS"
export const SAVE_STAFF_DOC_ATTACHMENTS = "SAVE_STAFF_DOC_ATTACHMENTS"
export const APPLY_STAFF_DOC_SIGNATURE = "APPLY_STAFF_DOC_SIGNATURE"
export const GET_TRAINING_LINKS = "GET_TRAINING_LINKS"
export const SAVE_TRAINING_LINKS = "SAVE_TRAINING_LINKS"
export const SAVE_STAFF_DOC_SEARCH_FILTERS= "SAVE_STAFF_DOC_SEARCH_FILTERS"
export const GET_CALENDAR_CATEGORY = "GET_CALENDAR_CATEGORY"
export const SAVE_CALENDAR_CATEGORY = "SAVE_CALENDAR_CATEGORY"
export const DELETE_CALENDAR_CATEGORY = "DELETE_CALENDAR_CATEGORY"
export const INSERT_CALENDAR_CATEGORY = "INSERT_CALENDAR_CATEGORY"
export const UPDATE_CALENDAR_CATEGORY = "UPDATE_CALENDAR_CATEGORY"
export const GET_DEFAULT_CALENDAR_CATEGORYS = "GET_DEFAULT_CALENDAR_CATEGORYS"
export const SAVE_DEFAULT_CALENDAR_CATEGORYS = "SAVE_DEFAULT_CALENDAR_CATEGORYS"
export const UPDATE_DEFAULT_CALENDAR_CATEGORYS = "UPDATE_DEFAULT_CALENDAR_CATEGORYS"
export const GET_METRICS_BY_TEMPLATE = "GET_METRICS_BY_TEMPLATE"
export const SAVE_METRICS_BY_TEMPLATE = "SAVE_METRICS_BY_TEMPLATE"
export const GET_METRICS_BY_DOC_STATUS = "GET_METRICS_BY_DOC_STATUS"
export const SAVE_METRICS_BY_DOC_STATUS = "SAVE_METRICS_BY_DOC_STATUS"
export const DELETE_STAFF_PLAN_DOC_ATTACHMENT = "DELETE_STAFF_PLAN_DOC_ATTACHMENT"
export const GET_SCHEDULER_FILTER = "GET_SCHEDULER_FILTER"
export const GET_SERVICE_RULE_LIST = "GET_SERVICE_RULE_LIST"
export const SAVE_SERVICE_RULE_LIST = "SAVE_SERVICE_RULE_LIST"
export const SAVE_SERVICE_RULE_DATA_LOADING = "SAVE_SERVICE_RULE_DATA_LOADING"
export const DELETE_SERVICE_RULE = "DELETE_SERVICE_RULE"
export const INSERT_SERVICE_RULE = "INSERT_SERVICE_RULE"
export const UPDATE_SERVICE_RULE = "UPDATE_SERVICE_RULE"
export const DYNAMIC_CHANGE_APP_ROUTE = "DYNAMIC_CHANGE_APP_ROUTE"
export const GET_UPCOMING_APPOINTMENTS = "GET_UPCOMING_APPOINTMENTS"
export const SAVE_UPCOMING_APPOINTMENTS = "SAVE_UPCOMING_APPOINTMENTS"
export const GET_UPCOMING_COMPLIANCE_COUNT_DATA = "GET_UPCOMING_COMPLIANCE_COUNT_DATA"
export const SAVE_UPCOMING_COMPLIANCE_COUNT_DATA = "SAVE_UPCOMING_COMPLIANCE_COUNT_DATA"
export const GET_DOC_METRICS_BY_SERVICE_CODE = "GET_DOC_METRICS_BY_SERVICE_CODE"
export const SAVE_DOC_METRICS_BY_SERVICE_CODE = "SAVE_DOC_METRICS_BY_SERVICE_CODE"

export const GET_CLIENT_DOC_SUPER_BILL = "GET_CLIENT_DOC_SUPER_BILL"
export const SAVE_CLIENT_DOC_SUPER_BILL = "SAVE_CLIENT_DOC_SUPER_BILL"

export const CLIENT_LEDGER_FILTER_OBJ = "CLIENT_LEDGER_FILTER_OBJ";

export const GET_CLIENT_LEDGER_PAYMENT_TYPES = "GET_CLIENT_LEDGER_PAYMENT_TYPES";
export const SAVE_CLIENT_LEDGER_PAYMENT_TYPES = "SAVE_CLIENT_LEDGER_PAYMENT_TYPES";

export const INSERT_CLIENT_LEDGER_RECORD = "INSERT_CLIENT_LEDGER_RECORD";
export const DELETE_CLIENT_LEDGER_RECORD = "DELETE_CLIENT_LEDGER_RECORD";

export const GET_CLIENT_LEDGER_BY_ID = "GET_CLIENT_LEDGER_BY_ID";
export const SAVE_CLIENT_LEDGER_BY_ID = "SAVE_CLIENT_LEDGER_BY_ID";

export const GET_CLIENT_LEDGER_BY_CLIENT_ID = "GET_CLIENT_LEDGER_BY_CLIENT_ID";
export const SAVE_CLIENT_LEDGER_BY_CLIENT_ID = "SAVE_CLIENT_LEDGER_BY_CLIENT_ID";
export const SAVE_STAFF_CREDENTIAL_INFO = "SAVE_STAFF_CREDENTIAL_INFO"
export const DELETE_STAFF_CREDENTIAL = "DELETE_STAFF_CREDENTIAL"
export const GET_STAFF_CREDENTIAL_LIST = "GET_STAFF_CREDENTIAL_LIST"
export const SAVE_STAFF_CREDENTIAL_LIST = "SAVE_STAFF_CREDENTIAL_LIST"
export const UPDATE_STAFF_CREDENTIAL = "UPDATE_STAFF_CREDENTIAL"
export const CLIENT_LIST_CHANGED = "CLIENT_LIST_CHANGED"
export const GET_CURRENT_LEDGER_BALANCE = "GET_CURRENT_LEDGER_BALANCE"
export const SAVE_CURRENT_LEDGER_BALANCE = "SAVE_CURRENT_LEDGER_BALANCE"
export const CLIENT_LEDGER_BALANCE_CHANGED = "CLIENT_LEDGER_BALANCE_CHANGED"
export const UPDATE_CLIENT_LEDGER = "UPDATE_CLIENT_LEDGER"

export const STAFF_AUDIT_FILTER = "STAFF_AUDIT_FILTER"
export const CLIENT_AUDIT_FILTER = "CLIENT_AUDIT_FILTER"
export const TIME_CLOCK_FILTER = "TIME_CLOCK_FILTER"

export const GET_STAFF_AUDIT_LOG_LIST = "GET_STAFF_AUDIT_LOG_LIST"
export const SAVE_STAFF_AUDIT_LOG_LIST = "SAVE_STAFF_AUDIT_LOG_LIST"
export const SAVE_STAFF_AUDIT_LOG_META_DATA = "SAVE_STAFF_AUDIT_LOG_META_DATA"
export const STAFF_AUDIT_LOG_LIST_CHANGED = "STAFF_AUDIT_LOG_LIST_CHANGED"


export const GET_CLIENT_AUDIT_LOG_LIST = "GET_CLIENT_AUDIT_LOG_LIST"
export const SAVE_CLIENT_AUDIT_LOG_LIST = "SAVE_CLIENT_AUDIT_LOG_LIST"
export const SAVE_CLIENT_AUDIT_LOG_META_DATA = "SAVE_CLIENT_AUDIT_LOG_META_DATA"
export const CLIENT_AUDIT_LOG_LIST_CHANGED = "CLIENT_AUDIT_LOG_LIST_CHANGED"

export const ASSIGN_ADDITIONAL_CLIENTS_TO_STAFF = "ASSIGN_ADDITIONAL_CLIENTS_TO_STAFF"
export const ASSIGN_STAFF_TO_SITE =  "ASSIGN_STAFF_TO_SITE"
export const FETCH_STAFF_LIST_AGAIN =  "FETCH_STAFF_LIST_AGAIN"
export const GET_COMPLIANCE_ATTACHED_DOC_LIST =  "GET_COMPLIANCE_ATTACHED_DOC_LIST"
export const SAVE_COMPLIANCE_ATTACHED_DOC_LIST =  "SAVE_COMPLIANCE_ATTACHED_DOC_LIST"
export const ADMIN_BOARD_FILTERS = "ADMIN_BOARD_FILTERS"
export const GET_SITES_FOR_CLINIC = "GET_SITES_FOR_CLINIC"
export const SAVE_SITES_FOR_CLINIC = "SAVE_SITES_FOR_CLINIC"
export const GET_REMINDER_SETTINGS = "GET_REMINDER_SETTINGS"
export const SAVE_REMINDER_SETTINGS = "SAVE_REMINDER_SETTINGS"
export const INSERT_REMINDER_SETTINGS = "INSERT_REMINDER_SETTINGS"
export const SAVE_INSERT_REMINDER_SETTINGS = "SAVE_INSERT_REMINDER_SETTINGS"
export const INSERT_REMINDER_EMAIL_SETTINGS = "INSERT_REMINDER_EMAIL_SETTINGS"
export const INSERT_REMINDER_TEXT_SETTINGS = "INSERT_REMINDER_TEXT_SETTINGS"
export const INSERT_REMINDER_PHONE_SETTINGS = "INSERT_REMINDER_PHONE_SETTINGS"
export const GET_AUDIT_LOGS = "GET_AUDIT_LOGS"
export const SAVE_AUDIT_LOGS = "SAVE_AUDIT_LOGS"

export const GET_CP_ACC_ACCESS_LIST = "GET_CP_ACC_ACCESS_LIST"
export const SAVE_CP_ACC_ACCESS_LIST = "SAVE_CP_ACC_ACCESS_LIST"
export const CREATE_CP_ACC_ACCESS = "CREATE_CP_ACC_ACCESS"
export const UPDATE_CP_ACC_ACCESS = "UPDATE_CP_ACC_ACCESS"
export const GET_CP_ACC_ACCESS_DETAIL = "GET_CP_ACC_ACCESS_DETAIL"
export const LOADING_IN_CLIENT = "LOADING_IN_CLIENT"
export const SAVE_CP_ACCESS_CREATE_RESP = "SAVE_CP_ACCESS_CREATE_RESP"
export const RESET_CP_ACCESS_PWD = "RESET_CP_ACCESS_PWD"


// client medications 

export const INSERT_CLIENT_MEDICATION = "INSERT_CLIENT_MEDICATION"
export const UPDATE_CLIENT_MEDICATION = "UPDATE_CLIENT_MEDICATION"
export const DELETE_CLIENT_MEDICATION = "DELETE_CLIENT_MEDICATION"
export const GET_CLIENT_MEDICATION_BY_CLIENT_ID = "GET_CLIENT_MEDICATION_BY_CLIENT_ID"
export const GET_CLIENT_MEDICATION_BY_ID = "GET_CLIENT_MEDICATION_BY_ID"

export const SAVE_CLIENT_MEDICATION_BY_CLIENT_ID = "SAVE_CLIENT_MEDICATION_BY_CLIENT_ID"
export const SAVE_CLIENT_MEDICATION_BY_ID = "SAVE_CLIENT_MEDICATION_BY_ID"
export const SAVE_SELECTED_ITEM_IN_CALENDER = "SAVE_SELECTED_ITEM_IN_CALENDER"

export const GET_INSURANCE_TYPES = "GET_INSURANCE_TYPES"
export const SAVE_INSURANCE_TYPES = "SAVE_INSURANCE_TYPES"

export const EXPORT_CASELOAD_REPORT_TO_EXCEL = "EXPORT_CASELOAD_REPORT_TO_EXCEL"
export const SAVE_EXPORT_CASELOAD_REPORT_TO_EXCEL = "SAVE_EXPORT_CASELOAD_REPORT_TO_EXCEL" 
export const GET_DOCUMENT_TEMPLATE_TYPE = "GET_DOCUMENT_TEMPLATE_TYPE" 
export const SAVE_DOCUMENT_TEMPLATE_TYPE = "SAVE_DOCUMENT_TEMPLATE_TYPE" 

export const SAVE_SOCIAL_DETERMINANTS = "SAVE_SOCIAL_DETERMINANTS"
export const GET_SOCIAL_DETERMINANTS = "GET_SOCIAL_DETERMINANTS" 
export const SAVE_SOCIAL_DETERMINANTS_RESPONSE = "SAVE_SOCIAL_DETERMINANTS_RESPONSE" 
export const UPDATE_SOCIAL_DETERMINANTS = "UPDATE_SOCIAL_DETERMINANTS"

export const LODING_INDICATOR = "LODING_INDICATOR"
export const GET_GLOBAL_SITES_FOR_CLINIC = "GET_GLOBAL_SITES_FOR_CLINIC"
export const SAVE_GLOBAL_SITES_FOR_CLINIC = "SAVE_GLOBAL_SITES_FOR_CLINIC"

export const UPDATE_CUSTOM_ROLE = "UPDATE_CUSTOM_ROLE"
export const CUSTOM_ROLE_CHANGED = "CUSTOM_ROLE_CHANGED"
export const GET_DOCUMENT_ATTACHMENT = "GET_DOCUMENT_ATTACHMENT"
export const SAVE_DOCUMENT_ATTACHMENT = "SAVE_DOCUMENT_ATTACHMENT"
export const GET_CLIENT_MESSAGE_LABELS = "GET_CLIENT_MESSAGE_LABELS"
export const SAVE_CLIENT_MESSAGE_LABELS = "SAVE_CLIENT_MESSAGE_LABELS"
export const ADD_CLIENT_MESSAGE_LABELS = "ADD_CLIENT_MESSAGE_LABELS"
export const UPDATE_CLIENT_MESSAGE_LABELS = "UPDATE_CLIENT_MESSAGE_LABELS"
export const DELETE_CLIENT_MESSAGE_LABELS = "DELETE_CLIENT_MESSAGE_LABELS"
export const MOVE_CLIENT_MESSAGE_TO_LEVEL = "MOVE_CLIENT_MESSAGE_TO_LEVEL"

export const GET_CONTACT_TYPE_LIST= "GET_CONTACT_TYPE_LIST"
export const SAVE_CONTACT_TYPE_LIST= "SAVE_CONTACT_TYPE_LIST"
export const GET_CLIENT_COLLATERAL_CONTACT= "GET_CLIENT_COLLATERAL_CONTACT"
export const SAVE_CLIENT_COLLATERAL_CONTACT= "SAVE_CLIENT_COLLATERAL_CONTACT"
export const SAVE_CLIENT_COLLATERAL_CONTACT_INFO= "SAVE_CLIENT_COLLATERAL_CONTACT_INFO"
export const UPDATE_CLIENT_COLLATERAL_CONTACT_INFO= "UPDATE_CLIENT_COLLATERAL_CONTACT_INFO" 
export const DELETE_CLIENT_COLLATERAL_CONTACT = "DELETE_CLIENT_COLLATERAL_CONTACT"
export const GET_CLIENT_COLLATERAL_CONTACT_BY_ID = "GET_CLIENT_COLLATERAL_CONTACT_BY_ID"
export const SAVE_CLIENT_COLLATERAL_CONTACT_BY_ID = "SAVE_CLIENT_COLLATERAL_CONTACT_BY_ID"
export const GET_CLIENT_DOCUMENT_ATTACHMENTS = "GET_CLIENT_DOCUMENT_ATTACHMENTS"
export const SAVE_CLIENT_DOCUMENT_ATTACHMENTS = "SAVE_CLIENT_DOCUMENT_ATTACHMENTS"
export const LOADER_CLIENTS = "LOADER_CLIENTS"
export const SET_DEFAULT_ROLE_TO_CUSTOM = "SET_DEFAULT_ROLE_TO_CUSTOM"
export const GET_COMPLIANCE_AUDIT_HISTORY = "GET_COMPLIANCE_AUDIT_HISTORY"
export const SAVE_COMPLIANCE_AUDIT_HISTORY = "SAVE_COMPLIANCE_AUDIT_HISTORY"

export const REMOVE_DOC_UNITS = "REMOVE_DOC_UNITS"
export const RECALCULATE_DOC_UNITS = "RECALCULATE_DOC_UNITS"
export const DOC_UNITS_CHANGED = "DOC_UNITS_CHANGED"
export const SAVE_REMINDER_LOADINGS='SAVE_REMINDER_LOADINGS'

export const UPDATE_TRANSSRVLINE_BILLING_STATUS = 'UPDATE_TRANSSRVLINE_BILLING_STATUS'
export const GET_BILLING_STATUSES = 'GET_BILLING_STATUSES'
export const SAVE_BILLING_STATUSES = 'SAVE_BILLING_STATUSES'
export const SAVE_BILLING_STATUS_ID = 'SAVE_BILLING_STATUS_ID'

export const GET_POS_LIST = 'GET_POS_LIST'
export const SAVE_POS_LIST = 'SAVE_POS_LIST'

export const SAVE_UPDATE_STAFF_COMMENT = 'SAVE_UPDATE_STAFF_COMMENT'
export const GET_STAFF_COMMENT = 'GET_STAFF_COMMENT'
export const SAVE_STAFF_COMMENT = 'SAVE_STAFF_COMMENT'

export const REFERRAL_SOURCE_DELETE = 'REFERRAL_SOURCE_DELETE'
export const REFERRAL_PROVIDER_DELETE = 'REFERRAL_PROVIDER_DELETE'
export const SAVE_REFERRAL_SOURCE_DELETE_RESP = 'SAVE_REFERRAL_SOURCE_DELETE_RESP'
export const SAVE_REFERRAL_PROVIDER_DELETE_RESP = 'SAVE_REFERRAL_PROVIDER_DELETE_RESP'

export const GET_MIN_SERVICE_RULE_LIST = "GET_MIN_SERVICE_RULE_LIST"
export const SAVE_MIN_SERVICE_RULE_LIST = "SAVE_MIN_SERVICE_RULE_LIST"
export const SAVE_MIN_SERVICE_RULE_DATA_LOADING = "SAVE_MIN_SERVICE_RULE_DATA_LOADING"
export const DELETE_MIN_SERVICE_RULE = "DELETE_MIN_SERVICE_RULE"
export const INSERT_MIN_SERVICE_RULE = "INSERT_MIN_SERVICE_RULE"
export const UPDATE_MIN_SERVICE_RULE = "UPDATE_MIN_SERVICE_RULE"

export const SAVE_AUTH_PAGE = 'SAVE_AUTH_PAGE'

/* cms 1500 */
export const GET_CMS_1500_BY_CLAIM_ID = 'GET_CMS_1500_BY_CLAIM_ID'
export const SAVE_CMS_1500_BY_CLAIM_ID = 'SAVE_CMS_1500_BY_CLAIM_ID'

export const GET_SERVICE_MIN_REPORT_BY_CLIENT_ID = 'GET_SERVICE_MIN_REPORT_BY_CLIENT_ID'
export const SAVE_SERVICE_MIN_REPORT_BY_CLIENT_ID = 'SAVE_SERVICE_MIN_REPORT_BY_CLIENT_ID'
export const GET_CMS_1500_FOR_BATCH = 'GET_CMS_1500_FOR_BATCH'
export const SAVE_CMS_1500_FOR_BATCH = 'SAVE_CMS_1500_FOR_BATCH'
export const GET_STAFF_REPORTS = "GET_STAFF_REPORTS"
export const GET_ALL_STAFF_REPORTS = "GET_ALL_STAFF_REPORTS"
export const SAVE_STAFF_REPORTS = "SAVE_STAFF_REPORTS"
export const SAVE_ALL_STAFF_REPORTS = "SAVE_ALL_STAFF_REPORTS"
export const POST_STAFF_REPORTS = "POST_STAFF_REPORTS"
export const FETCH_STAFF_REPORTS = "FETCH_STAFF_REPORTS"
export const FETCH_ALL_STAFF_REPORTS = "FETCH_ALL_STAFF_REPORTS"

export const GET_CURRENT_TASK = 'GET_CURRENT_TASK'
export const SAVE_CURRENT_TASK = 'SAVE_CURRENT_TASK'
export const UPDATE_TASK_STATUS_VALUE = 'UPDATE_TASK_STATUS_VALUE'
export const CURRENT_TASK_LODING_STATE = 'CURRENT_TASK_LODING_STATE'

/* report manager */
export const GET_REPORT_TYPES = "GET_REPORT_TYPES"
export const GET_REPORTS_BY_REPORT_TYPE = "GET_REPORTS_BY_REPORT_TYPE"
export const SAVE_REPORT_TYPES = "SAVE_REPORT_TYPES"
export const SAVE_REPORTS_BY_REPORT_TYPE = "SAVE_REPORTS_BY_REPORT_TYPE"
export const FETCH_REPORT_TYPES = "FETCH_REPORT_TYPES"
export const GET_REPORT_COLUMNS = "GET_REPORT_COLUMNS"
export const SAVE_REPORT_COLUMNS = "SAVE_REPORT_COLUMNS"
export const GET_STAFF_REPORT_COLUMN_PREFERENCES = "GET_STAFF_REPORT_COLUMN_PREFERENCES"
export const SAVE_STAFF_REPORT_COLUMN_PREFERENCES = "SAVE_STAFF_REPORT_COLUMN_PREFERENCES"
export const INSERT_STAFF_REPORT_COLUMN_PREFERENCES = "INSERT_STAFF_REPORT_COLUMN_PREFERENCES"
export const ASSIGN_REPORTS_TO_ROLE = "ASSIGN_REPORTS_TO_ROLE"
export const GET_ROLE_REPORTS = "GET_ROLE_REPORTS"
export const SAVE_ROLE_REPORTS = "SAVE_ROLE_REPORTS"
export const GET_ASSIGNED_STAFF_OF_REPORT = "GET_ASSIGNED_STAFF_OF_REPORT"
export const SAVE_ASSIGNED_STAFF_OF_REPORT = "SAVE_ASSIGNED_STAFF_OF_REPORT"
export const ASSIGN_REPORT_TO_MULTIPLE_STAFF = "ASSIGN_REPORT_TO_MULTIPLE_STAFF"
export const GET_STAFF_REPORTS_WITH_TYPES = "GET_STAFF_REPORTS_WITH_TYPES"
export const SAVE_STAFF_REPORTS_WITH_TYPES = "SAVE_STAFF_REPORTS_WITH_TYPES"
export const GET_CLIENTS_FOR_CASELOAD_MAP = "GET_CLIENTS_FOR_CASELOAD_MAP"
export const SAVE_CLIENTS_FOR_CASELOAD_MAP = "SAVE_CLIENTS_FOR_CASELOAD_MAP"

/* Announcements  */
export const GET_ANNOUNCEMENTS = "GET_ANNOUNCEMENTS"
export const SAVE_ANNOUNCEMENTS = "SAVE_ANNOUNCEMENTS"
export const DELETE_ANNOUNCEMENTS = "DELETE_ANNOUNCEMENTS"
export const GET_ANNOUNCEMENTS_BY_ID = "GET_ANNOUNCEMENTS_BY_ID"
export const SAVE_ANNOUNCEMENTS_BY_ID = "SAVE_ANNOUNCEMENTS_BY_ID"
export const SAVE_ANNOUNCEMENTS_LIST = "SAVE_ANNOUNCEMENTS_LIST"
export const UPDATE_ANNOUNCEMENTS_LIST = "UPDATE_ANNOUNCEMENTS_LIST"
export const GET_ALL_ANNOUNCEMENTS = "GET_ALL_ANNOUNCEMENTS"
export const ANNOUNCEMENTS_REFRESH_STATE = "ANNOUNCEMENTS_REFRESH_STATE"
