import React, { useState, useEffect } from "react";
import Loader from "../../../control-components/loader/loader";
import DrawerContainer from "../../../control-components/custom-drawer/custom-drawer";
import StaffProfileHeader from "../staff-profile/staff-profile-header";
import { useDispatch, useSelector } from "react-redux";
import { permissionEnum } from "../../../helper/permission-helper";
import AssignStaffReport from ".";
import { fetchStaffReportsAction, getAllStaffReports, getStaffReports } from "src/redux/actions";

const AssignStaffReportHeader = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [available, setAvailable] = useState([]);
  const [assigned, setAssigned] = useState([]);
  const selectedStaffId = useSelector((state: any) => state.selectedStaffId);
  const userAccessPermission = useSelector(
    (state: any) => state.userAccessPermission
  );
  const { staffReportsResp, allStaffReportsResp, fetchStaffReports, fetchAllStaffReports } = useSelector((state: any) => state.StaffReducer);

  const dispatch = useDispatch()

  useEffect(() => {
    if(fetchStaffReports) {
      const data = {
        staffId: selectedStaffId
      }
      dispatch(getStaffReports(data))
      dispatch(fetchStaffReportsAction(false))
    }
  }, [fetchStaffReports]);

  useEffect(() => {
    if(selectedStaffId) {
      dispatch(fetchStaffReportsAction(true))
    }
  }, [selectedStaffId])

  useEffect(() => {
    if(fetchAllStaffReports) {
      dispatch(getAllStaffReports())
    }
  }, [fetchAllStaffReports]);

  useEffect(() => {
    if(staffReportsResp.length > 0) {
        setLoading(true);    
        const data = staffReportsResp.map((item) => {
          return {
            reportId: item.reportId,
            reportName: `${item.reportName}`,
          };
        });
        if (userAccessPermission[permissionEnum.MANAGE_STAFF_SITES]) {
          setAssigned(data);
        }
        setLoading(false);    
      }
  }, [staffReportsResp]);

  useEffect(() => {
    if(allStaffReportsResp.length > 0) {
        setLoading(true);    
        const data = allStaffReportsResp.map((item) => {
          return {
            reportId: item.reportId,
            reportName: `${item.reportName}`,
          };
        });
        if (userAccessPermission[permissionEnum.MANAGE_STAFF_SITES]) {
          setAvailable(data);
        }
        setLoading(false);    
      }
  }, [allStaffReportsResp]);

  return (
    <div className="d-flex flex-wrap">
      {loading === true && <Loader />}
      <div className="inner-dt col-md-3 col-lg-2">
        <DrawerContainer />
      </div>
      <div className="col-md-9 col-lg-10 ">
        <StaffProfileHeader />

        <div className="px-2 mt-4">
          <div className=" ">
            <AssignStaffReport available={available} assigned={assigned} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignStaffReportHeader;
