import { ComponentState, useEffect, useState } from "react";
import Loader from "../../../control-components/loader/loader";
import InputKendoRctV1 from "src/control-components/input/inputV1";
import { useNavigate } from "react-router";
import { Error } from "@progress/kendo-react-labels";
import ErrorHelper from "../../../helper/error-helper";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useDispatch, useSelector } from "react-redux";
import { saveInsertClinicianResponse } from "src/redux/actions";
import { filterBy } from "@progress/kendo-data-query";
import { clinicServices } from "src/services/clinicService";
import { GenericResponse } from "src/dataModels/authSetModels";
import { DeaModel } from "src/dataModels/clinicResponseModels";
import { Dialog } from "@progress/kendo-react-dialogs";
import { NotificationManager } from "react-notifications";
import apiHelper from "src/helper/api-helper";
import { renderErrors } from 'src/helper/error-message-helper';
import { Switch } from "@progress/kendo-react-inputs";
import { API_ENDPOINTS } from "src/services/api-endpoints";
interface RoleItem {
    id: number;
    name: string;
    isPrimary: boolean;
}
interface RoleItem {
    id: number;
    name: string;
    isPrimary: boolean;
}
const AddNewClinician = ({ onClose, selectedDosespotClinician }) => {
    const navigate = useNavigate();
    const [settingError, setSettingError] = useState(false);
    const [errors, setErrors] = useState<any>({});
    const [filter, setFilter] = useState<any>();
    const [isNPiNeeded, setIsNpiNeeded] = useState(false);
    const [isDeaRequired, setIsDeaRequired] = useState(false);
    const [isApiCalled, setInitialApiCalled] = useState(false);
    const [primaryRoleData, setRoleData] = useState<RoleItem[]>([]);
    const [secondaryRoleData, setSecondaryRoleData] = useState<RoleItem[]>([]);
    const [doseSpotStaffList, setDoseSpotStaffList] = useState([])
    const emptyDea: DeaModel = { deaNumber: "", state: "" }
    const [dea, setDea] = useState<DeaModel[]>([emptyDea])
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const state: ComponentState = useSelector(states => {
        return states;
    });
    const [isReportingClinician, setReportingClinician] = useState(false);
    const { insertClinicianResponse } = state['ClinicsReducer'];
    const defaultPrimaryRoleValue = { id: 0, name: '--Select Primary Role--', isPrimary: false };
    const defaultSecRoleValue = { id: 0, name: '--Select Secondary  Role--', isPrimary: false };
    const defaultStaffValue = { id: 0, name: '--Select Staff--', isPrimary: false };
    const reportingClinicianId = 2;
    let [fields, setFields] = useState({
        staff: {} as any,
        primaryRole: {} as RoleItem,
        secondaryRole: {} as RoleItem,
        fax: "",
        npi: "",
        useClinicAddress: false,
        isReportingClinician: false
    });

    useEffect(() => {
        setLoading(true);
        if (!isApiCalled) {
            setInitialApiCalled(true)
            getDosespotStaffList();
            setLoading(false);
        }
    }, [isApiCalled]);

    useEffect(() => {
        if (insertClinicianResponse && insertClinicianResponse != null) {
            dispatch(saveInsertClinicianResponse())
            navigate(-1)
        }
    }, [insertClinicianResponse]);
    const getRole = async (staffList) => {
        const serviceRoleList: GenericResponse = await clinicServices.getPrescriptionRoleList()
        if (serviceRoleList && serviceRoleList.resultData) {
            var pRole = (serviceRoleList.resultData.filter(function (e) { return e.isPrimary == true }));
            var secRole = serviceRoleList.resultData.filter(function (e) { return e.isPrimary == false });
            setRoleData(pRole);
            setSecondaryRoleData([defaultSecRoleValue, ...secRole]);
            fillEditForm(staffList, pRole, secRole);
        }
    }
    const fillEditForm = async (staffList, primaryRole, secRole) => {
        if (selectedDosespotClinician?.doseSpotClinicianId) {
            const selectedPrimaryRole = primaryRole.filter((item1) => selectedDosespotClinician.clinicianRoles.some((item2) => item1.id === item2.dosespotRoleId));
            const selectedSecondaryRole = secRole.filter((item1) => selectedDosespotClinician.clinicianRoles.some((item2) => item1.id === item2.dosespotRoleId));
            setFields({
                ...fields,
                primaryRole: selectedPrimaryRole[0],
                secondaryRole: selectedSecondaryRole.length > 0 ? selectedSecondaryRole[0] : defaultSecRoleValue,
                staff: staffList.find((item) => item.id === selectedDosespotClinician.staffId),
                fax: selectedDosespotClinician.primaryFax,
                npi: selectedDosespotClinician.npiNumber
            });
            setDea(selectedDosespotClinician.deaNumbers);
            setReportingClinician(selectedDosespotClinician.clinicianRoles.some(item => item.dosespotRoleId === reportingClinicianId))
            setIsNpiNeeded(selectedPrimaryRole[0]?.id === 1);
        } else {
            setFields({
                ...fields,
                staff: defaultStaffValue,
                primaryRole: defaultPrimaryRoleValue,
                secondaryRole: defaultSecRoleValue,
            });
        }
    }
    useEffect(() => {
        if (dea != null) {

        }
    }, [dea]);
    const handleChange = (e) => {
        errors.role = null;
        errors.staff = null;
        const name = e.target.name;
        let value = e.target.value;
        if (name === 'primaryRole') {
            if (value?.id === 1) {
                setIsNpiNeeded(true);
                setIsDeaRequired(true);
            }
            else {
                setIsDeaRequired(false);
                setIsNpiNeeded(false)
            }
        }
        setFields({
            ...fields,
            [name]: value,
        });
    };
    const handleSecondaryRoleChange = (e) => {
        const name = e.target.name;
        let value = e.target.value;
        setFields({
            ...fields,
            [name]: value,
        });
    };
    function onFilterChange(event, listName) {
        setFilter({ ...filter, [listName]: event.filter });
    }
    const handleSubmit = (event) => {
        if (handleValidation()) {
            setLoading(true);
            const roleArray: number[] = []
            roleArray.push(fields?.primaryRole?.id);
            if (fields?.secondaryRole?.id > 0)
                roleArray.push(fields?.secondaryRole?.id)
            if (isReportingClinician)
                roleArray.push(reportingClinicianId)
            let requestObj: any = {
                staffId: fields.staff.id,
                primaryFax: fields.fax,
                clinicianRoleType: roleArray,
                npiNumber: fields.npi,
                useClinicAddress: fields.useClinicAddress
            }
            if (isNPiNeeded) {
                requestObj = { ...requestObj, npiNumber: fields.npi, }
            }
            requestObj = { ...requestObj, deaNumbers: dea }
            requestObj = selectedDosespotClinician.doseSpotClinicianId ? { ...requestObj, clinicianId: selectedDosespotClinician.doseSpotClinicianId } : requestObj;
            var apiEndPoint = selectedDosespotClinician.doseSpotClinicianId ? API_ENDPOINTS.EDIT_CLINICIAN : API_ENDPOINTS.SAVE_CLINICIAN;
            apiHelper.postRequest(`${apiEndPoint}`, requestObj).then((result) => {
                if (result.statusCode == 200) {
                    setLoading(false);
                    onClose();
                    if (selectedDosespotClinician?.doseSpotClinicianId)
                        NotificationManager.success("eRx clinician updated successfully!");
                    else
                        NotificationManager.success("eRx clinician added successfully!");
                }
            }).catch((err) => {
                setLoading(false);
                renderErrors(err);
            }).finally(() => {
                setLoading(false);
            });
        }
    };
    const getDosespotStaffList = () => {
        apiHelper.getRequest(`${API_ENDPOINTS.GET_STAFF_DDL_BY_CLINIC_ID}` + true).then((result) => {
            if (result.statusCode == 200) {
                //setLoading(false);
                setDoseSpotStaffList(result?.resultData);
                getRole(result?.resultData)
            }
        }).catch((err) => {
            setLoading(false);
            renderErrors(err);
        }).finally(() => {
            setLoading(false);
        });
    }
    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        var pattern = new RegExp(/^[0-9\b]+$/);
        if (!fields.staff || !fields.staff.id) {
            formIsValid = false;
            errors["staff"] = ErrorHelper.FIELD_BLANK;
        }
        if (fields?.primaryRole === null || fields?.primaryRole?.id === 0) {
            formIsValid = false;
            errors["role"] = ErrorHelper.FIELD_BLANK;
        }
        if (isDeaRequired && (!dea || dea.length === 0 || (dea.length == 1 && dea[0].deaNumber.length < 1))) {
            formIsValid = false;
            errors["dea"] = ErrorHelper.FIELD_BLANK;
        }
        dea.forEach(element => {
            if (element.deaNumber.length > 0 && element.state.length < 1) {
                formIsValid = false;
            }
        });
        for (var deaData in dea) { }
        if (!fields.fax || fields.fax.trim().length === 0) {
            formIsValid = false;
            errors["fax"] = ErrorHelper.FIELD_BLANK;
        }
        if (isNPiNeeded && (fields.npi === null || !fields.npi || fields.npi.trim().length === 0)) {
            formIsValid = false;
            errors["npi"] = ErrorHelper.FIELD_BLANK;
        }
        setErrors(errors);
        return formIsValid;
    };
    const handleSwitch = (e: any) => {
        var value = e.target.value;
        var name = e.target.name;
        setFields({
            ...fields,
            [name]: value,
        });
    };
    const handleReportingClinicianSwitch = (e: any) => {
        setReportingClinician(e.target.value);
    };
    useEffect(() => {
        if (selectedDosespotClinician) {

        }
    }, [selectedDosespotClinician]);

    return (
        <Dialog
            onClose={onClose}
            title={selectedDosespotClinician?.doseSpotClinicianId ? "Update eRx Staff" : "Add eRx Staff"}
            className="dialog-modal xl-modal width_90 blue_theme" height={"65%"} width={"60%"}>
            <div className=" edit-Service-popup">
                {loading === true && <Loader />}
                <div className="mx-3">
                    <div className="row">
                        <div className="mb-2 col-lg-3 col-md-6 col-12 list-staff-drop">
                            <label>Staff</label>
                            <DropDownList
                                filterable={true}
                                data={filterBy(doseSpotStaffList, filter ? filter.doseSpotStaffList : [])}
                                onFilterChange={(event) => {
                                    onFilterChange(event, "doseSpotStaffList");
                                }}
                                textField="name"
                                // label="Staff"
                                name="staff"
                                value={fields.staff}
                                onChange={handleChange}
                            />
                            {(errors && errors.staff && errors.staff != null) && <Error>{errors.staff}</Error>}
                        </div>
                        <div className="mb-2 col-lg-3 col-md-6 col-12 list-staff-drop">
                            <label>Primary Role</label>
                            <DropDownList
                                data={primaryRoleData}
                                textField="name"
                                // label="Primary Role"
                                name="primaryRole"
                                disabled={false}
                                onChange={handleChange}
                                value={fields.primaryRole}
                            />
                            {(errors && errors.role && errors.role != null) && <Error>{errors.role}</Error>}
                        </div>
                        <div className="mb-2 col-lg-3 col-md-6 col-12 list-staff-drop">
                            <label>Secondary Role</label>
                            <DropDownList
                                data={secondaryRoleData}
                                textField="name"
                                // label="Secondary Role"
                                name="secondaryRole"
                                disabled={false}
                                value={fields.secondaryRole}
                                onChange={handleSecondaryRoleChange}
                            />
                        </div>
                        <div className="mb-2 col-lg-3 col-md-6 col-12 list-staff-drop" style={{ display: "flex", alignItems: "center", paddingLeft: "10px", marginTop: "20px" }}>
                            <Switch
                                onChange={handleReportingClinicianSwitch}
                                onLabel={"on"}
                                offLabel={"off"}
                                name="reportingClinician"
                                value={isReportingClinician}
                                checked={isReportingClinician}
                            />
                            <span className="switch-title-text ml-2  fw-500 f-16 ">
                                Reporting Clinician
                            </span>
                        </div>
                        <div className="mb-2 col-lg-3 col-md-6 col-12 list-staff-drop">
                            <InputKendoRctV1
                                value={fields.fax}
                                onChange={handleChange}
                                name="fax"
                                label="Fax"
                                error={fields.fax == "" && errors.fax}
                                validityStyles={settingError}
                                required={true}
                                placeholder="Fax"
                                maxLength={10}
                            />
                        </div>
                        {isNPiNeeded && <div className="mb-2 col-lg-3 col-md-6 col-12 list-staff-drop">
                            <InputKendoRctV1
                                validityStyles={settingError}
                                value={fields.npi}
                                onChange={handleChange}
                                name="npi"
                                label="NPI"
                                error={(fields.npi === "" || fields.npi === null) && errors.npi}
                                required={true}
                                placeholder="NPI"
                            />
                        </div>
                        }
                        <div className="mb-2 col-lg-3 col-md-6 col-12 list-staff-drop" style={{ display: "flex", alignItems: "center", paddingLeft: "10px", marginTop: "20px" }}>
                            <Switch
                                onChange={handleSwitch}
                                onLabel={"on"}
                                offLabel={"off"}
                                name="useClinicAddress"
                                value={fields.useClinicAddress}
                                checked={fields.useClinicAddress}
                            />
                            <span className="switch-title-text ml-2  fw-500 f-16 ">
                                Use Clinic Address
                            </span>
                        </div>
                    </div>
                    {isNPiNeeded && <div>
                        <div className="f-24">DEA details </div>
                        {
                            dea.map((currentDea, indexDea) => {
                                return <div className="row">

                                    <div className="mb-2 col-lg-3 col-md-6 col-12 list-staff-drop">
                                        <InputKendoRctV1
                                            value={currentDea.deaNumber}
                                            onChange={(handleTextChange) => {
                                                errors.dea = null;
                                                setDea(list => list.map((item: DeaModel, i) => i === indexDea ? {
                                                    ...item,
                                                    deaNumber: '' + handleTextChange.value
                                                }
                                                    : item
                                                ));
                                            }
                                            }
                                            label=""
                                            validityStyles={settingError}
                                            required={true}
                                            placeholder="DEA Number"
                                        /></div>

                                    <div className="mb-2 col-lg-3 col-md-6 col-12 list-staff-drop">
                                        <InputKendoRctV1
                                            value={currentDea.state}
                                            onChange={(handleTextChange) => {
                                                setDea(list => list.map((item, i) =>
                                                    i === indexDea
                                                        ? {
                                                            ...item,
                                                            state: '' + ((/^[a-zA-Z\s]*$/.test(handleTextChange.value)) ? handleTextChange.value : "")
                                                        }
                                                        : item
                                                ));
                                            }}
                                            name="dea"
                                            // error={fields.dea == "" && errors.dea}
                                            validityStyles={settingError}
                                            required={true}
                                            placeholder="State"
                                            maxLength={2}
                                        />
                                        {(currentDea.deaNumber && currentDea.deaNumber.length > 0 && currentDea.state.length < 1) && <Error>{'* Required'}</Error>}
                                    </div>
                                    {(dea && dea.length > 1)
                                        && <div className="mb-2 col-lg-3 col-md-6 col-12 list-staff-drop">
                                            <i onClick={
                                                () => {
                                                    const updatedDeaList = dea.filter((item, i) => i !== indexDea);
                                                    setDea(updatedDeaList)
                                                }
                                            }
                                                className="fa-solid fa-xmark cross-red"></i>


                                        </div>
                                    }
                                </div>
                            })
                        }
                        {errors.dea && <Error>{'*Dea can not be empty'}</Error>}

                        <button
                            onClick={() => {
                                setDea(oldArray => [...oldArray, emptyDea]);
                            }}
                            className="AddMore"><i className="fa-solid fa-plus"></i> Add another DEA</button>

                    </div>
                    }
                </div>
                {loading == true && <Loader />}
            </div>
            <div className="preview-btn-bottom">

                <div className="border-bottom-line"></div>
                <div className="d-flex mt-4">
                    <div className="right-sde">
                        <button
                            className="submitButon "
                            onClick={handleSubmit}
                        >
                            {selectedDosespotClinician?.doseSpotClinicianId ? "Update" : "Submit"}
                        </button>
                    </div>
                    <div className="right-sde-grey">
                        <button
                            className="cancelBtn"
                            onClick={() => { onClose(); }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}
export default AddNewClinician;