import { Error } from "@progress/kendo-react-labels";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { loaderAction } from "src/redux/actions";
import { onShowFileTempFields } from "./file-template-utility";

import DocumentTemplate from 'notenetic-document-template';
import { showError } from "src/util/utility";

export function AddDocumentFileTemplate({
    name,
    onComponentLoaded,
    controlErrors,
    showValidationError,
    focusEnable,
    selectedPatientList,
    isEdit,
    onGoalsDataStateChange,
    preselctedGoalEdit,
    isViewDoc,
    dxPreselectedData,
    onDxStateChange,
    tpId,
    isDraft,
    draftCommonControlls,
    docFieldMappings,
    isViewMode,
    docIndex = 0,
    documentId,
    isClientEdit = false
    // setHtmlFileValidationError,
}) {

    const itemsRef = useRef(null);
    const [errorList, setErrorList] = useState([]);
    const [ComponentToRender, setComponentToRender] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (ComponentToRender && onComponentLoaded) {
            // console.log("Component load called on component rendered")
            onComponentLoaded();
        }
    }, [ComponentToRender])

    useEffect(() => {
        // console.log("Loading component", name);
        // Dynamically import the component
        importTemplate()
    }, [name]);



    async function importTemplate() {
        // setComponentToRender(() => (props) => <DocumentTemplate templateName={name} {...props} />);
        import(`./file-template/${name}`)
            .then((module) => setComponentToRender(() => {
                // console.log("Got component", name);
                if (module.default) return module.default;
                // console.log("module name ", name);
                return module[name];
            }))
            .catch((error) => {
                // console.error(`Error loading component: ${name}`, error);
                try {
                    DocumentTemplate({ templateName: name })
                    // console.log("template", template)
                    setComponentToRender(() => (props) => <DocumentTemplate templateName={name} {...props} />);
                } catch (error) {
                    setComponentToRender(() => (props) => <div>The selected template ({name}) is not available at the moment. Please contact support.</div>)
                }
                if (onComponentLoaded) {
                    // console.log("Component load called on component failure for ", name)
                    onComponentLoaded()
                }
            });
    }

    useEffect(() => {
        if (ComponentToRender && docFieldMappings && docFieldMappings.length && docFieldMappings.length > 0) {
            dispatch(loaderAction(true))
            setTimeout(() => {
                // console.log("Filling data..")
                onShowFileTempFields(docFieldMappings, documentId, isViewMode, name, docIndex, isClientEdit);
                dispatch(loaderAction(false))

            }, 2000) // Give time to component to render on screen.
        } else {
            // console.log("Can't fill data at this time because of one is not correct:", ComponentToRender, docFieldMappings)
        }
    }, [ComponentToRender])

    useEffect(() => {
        const handleChange = (_) => {
            // setHtmlFileValidationError()
        };

        const formEl = document.getElementById(name);
        formEl?.addEventListener("change", handleChange);

        return () => {
            formEl?.removeEventListener("change", handleChange);
        };
    }, []);

    useEffect(() => {
        let errorList =
            !showValidationError || !controlErrors || controlErrors.length < 1
                ? []
                : controlErrors;
        setErrorList(errorList);
    }, [showValidationError, controlErrors]);

    useEffect(() => {
        if (focusEnable && errorList && errorList.length > 0) {
            const uniqueIndex = errorList[0].id;
            if (
                itemsRef.current &&
                itemsRef.current[uniqueIndex] &&
                itemsRef.current[uniqueIndex].focus
            ) {
                itemsRef.current[uniqueIndex].focus();
            }
        }
    }, [errorList, focusEnable]);

    function showErrorFor(id) {
        if (!errorList) return <></>;
        const error = errorList.find((error) => error.id === id);
        if (!error) return <></>;
        return <Error>{error.msg}</Error>;
    }

    return (ComponentToRender) ? (
        <ComponentToRender
            itemsRef={itemsRef}
            showErrorFor={showErrorFor}
            selectedPatientList={selectedPatientList}
            isEdit={isEdit}
            onGoalsDataStateChange={onGoalsDataStateChange}
            preselctedGoalEdit={preselctedGoalEdit}
            isViewDoc={isViewDoc}
            dxPreselectedData={dxPreselectedData}
            onDxStateChange={onDxStateChange}
            tpId={tpId}
            isDraft={isDraft}
            draftCommonControlls={draftCommonControlls}
        />
    ) : (
        <></>
    );
}