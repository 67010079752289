import { Dialog } from "@progress/kendo-react-dialogs";
import React, { ComponentState, useRef, useState } from "react";
import DatePickerKendoRct from "../../control-components/date-picker/date-picker";
import InputKendoRct from "../../control-components/input/input";
import TimePickerKendoRct from "../../control-components/time-picker/time-picker";
import ApiHelper from "../../helper/api-helper";
import ErrorHelper from "../../helper/error-helper";
import { mergeDateAndTimeInToUtc } from "../../util/utility";
import DropDownKendoRct from "../../control-components/drop-down/drop-down";
import { Encrption } from "../encrption";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import Loading from "../../control-components/loader/loader";
import moment from "moment";
import InputKendoRctV1 from "src/control-components/input/inputV1";
import { API_ENDPOINTS } from "src/services/api-endpoints";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";

import calenderIcon from "../../assets/images/calendar-03.png"
const AddDocumentSignature = ({
  insertDocumentStaffSign,
  insertClientSignature /* (pin, sigDateTime) */,
  onClose,
  serviceDate,
  template,
  signStaffId,
  documentName,
  setIsParent,
  mainLoading,
  docSettings,
  viewDoc,
  isStaffDoc,
  isstaffSignConsent=false

}) => {
  const staffInfo = useSelector((state: ComponentState) => state.getStaffReducer);

  // States
  const [isPinAvailable, setIsPinAvailable] = useState(false);
  const [loading, setLoading] = useState(false);

  const [fields, setFields] = useState({
    pinCode: null,
    signDate: !docSettings?.canChooseSigDate ? new Date() : null,
    // signTime: null,
    signType: null,
  });


  const [errors, setErrors] = useState<any>({
    pinCode: null,
    signDate: null,
    // signTime: null,
    signType: null,
  });
  const [settingError, setSettingError] = useState(false);
  const [showPin, setShowPin] = useState("password");
  const [sigControlDisabled, setSigControlDisabled] = useState(true);
  const optionData = [
    "Staff Signature",
    "Client Signature",
    "Parent Signature",
  ];
  const filteredOptionData = optionData;
  const staffId = useSelector((state: ComponentState) => state.loggedIn?.staffId);
  const [isButtonDisable, setButtonDisable] = useState(false);
  const [signText, setSignText] = useState("");
  const [staffCheckbox, setStaffCheckbox] = useState(false);


  /* ============================= Ref ============================= */

  const selectedTypeRef = useRef(null)
  const dateOfSignatureRef = useRef(null)
  const timeOfSignatureRef = useRef(null)
  const signaturePinRef = useRef(null)
  const submitRef = useRef(null);
  const applyApproveRef = useRef(null)
  const checkboxRef = useRef(null)

  const steps = {
    hour: 1,
    minute: 1,
  };
  /* ============================= useEffect functions ============================= */

  useEffect(() => {
    if (!template?.canApplyClientSig) {
      if (signStaffId == staffId) {
        setButtonDisable(true);
        setSignText("Signature already applied!");
      } else {
        setButtonDisable(false);
        setSignText("");
      }
    }
  });

  /* ============================= Private functions ============================= */

  const handleValidation = () => {
    let focusRef;
    let errors: any = { signDate: null, };
    let formIsValid = true;


    if (!fields || !fields.signDate || fields.signDate == null) {
      formIsValid = false;
      errors.signDate = ErrorHelper.FIELD_BLANK;
      if (!focusRef || focusRef?.current == null) {
        focusRef = dateOfSignatureRef;
      }
    }
    // if (!fields || !fields.signTime || fields.signTime == null) {
    //   formIsValid = false;
    //   errors["signTime"] = ErrorHelper.FIELD_BLANK;
    //   if (!focusRef || focusRef?.current == null) {
    //     focusRef = timeOfSignatureRef;
    //   }
    // }

    if (template?.canApplyClientSig) {
      if (!fields || !fields.signType || fields.signType == null) {
        formIsValid = false;
        errors["signType"] = ErrorHelper.FIELD_BLANK;
        if (!focusRef || focusRef?.current == null) {
          focusRef = signaturePinRef;
        }
      }
    }

    if ((fields?.signType === "Staff Signature" || isstaffSignConsent) && staffCheckbox === false) {
      formIsValid = false;
      errors["staffSignType"] = ErrorHelper.CHECKBOX_REQUIRED;
      if (!focusRef || focusRef?.current == null) {
        focusRef = checkboxRef;
      }
    }

    if (!fields || !fields.pinCode || fields.pinCode == null || (fields.pinCode ? fields.pinCode : '').trim().length === 0) {
      formIsValid = false;
      errors["pinCode"] = ErrorHelper.FIELD_BLANK;
      if (!focusRef || focusRef?.current == null) {
        focusRef = selectedTypeRef;
      }
    } else if (!fields || (fields.pinCode ? fields.pinCode : '').trim().length != 4) {
      formIsValid = false;
      errors["pinCode"] = ErrorHelper.PIN_LENGTH;
      if (!focusRef || focusRef?.current == null) {
        focusRef = selectedTypeRef;
      }
    } else if (isPinAvailable === false) {
      formIsValid = false;
      errors["pinCode"] = ErrorHelper.INVALID_PIN;
      if (!focusRef || focusRef?.current == null) {
        focusRef = selectedTypeRef;
      }
    }

    setErrors(errors);
    return formIsValid;
  };

  const validateStaffPin = (pinCode) => {
    setLoading(true);
    ApiHelper.getRequest(API_ENDPOINTS.VALIDATE_STAFF_PIN + Encrption(pinCode))
      .then((result) => {
        setIsPinAvailable(result.resultData && result.resultData.isValid);
      })
      .catch((_) => {
        setIsPinAvailable(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const validateClientPin = (pinCode) => {
    let signTypeCLient = fields.signType == "Parent Signature" ? true : false;
    setLoading(true);
    ApiHelper.getRequest(
      API_ENDPOINTS.VALIDATE_CLIENT_PIN +
      "pin" +
      "=" +
      Encrption(pinCode) +
      "&" +
      "clientId" +
      "=" +
      documentName?.clientId +
      "&" +
      "isParentSig" +
      "=" +
      signTypeCLient
    )
      .then((result) => {
        setIsPinAvailable(result.resultData && result.resultData.isValid);
      })
      .catch((_) => {
        setIsPinAvailable(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /* ============================= Event functions ============================= */

  const handleShowPin = () => {
    setShowPin(showPin === "password" ? "text" : "password");
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setSigControlDisabled(false);
    if (name == "signType" && value == "Parent Signature") {
      setIsParent(true);
    }
    if (name == "signType" && value == "Client Signature") {
      setIsParent(false);
    }

    // if (template?.canApplyClientSig) {
    //   if (name == "signType") {
    //     if (signStaffId == staffId && value == "Staff Signature") {
    //       setButtonDisable(true);
    //       setSignText("Signature already applied!");
    //     } else {
    //       setButtonDisable(false);
    //       setSignText("");
    //     }
    //   }
    // }

    if (name === "pinCode") {
      if (value.length == 4) {
        if (fields.signType == "Client Signature") {
          validateClientPin(value);
        } else if (fields.signType == "Parent Signature") {
          validateClientPin(value);
        } else {
          validateStaffPin(value);
        }
      } else {
        setIsPinAvailable(false);
      }
    }
    setFields({ ...fields, [name]: value });
  };

  const handleSubmit = (event?: any, buttonName?: any) => {
    setSettingError(true);
    if (handleValidation()) {
      if (fields.signType == "Client Signature") {
        insertClientSignature(
          fields.pinCode,
          fields.signDate,
          "00",// fields.signTime,
          fields.signType
        );
      } else if (fields.signType == "Parent Signature") {
        insertClientSignature(
          fields.pinCode,
          fields.signDate,
          "00",// fields.signTime,
          fields.signType
        );
      } else {
        insertDocumentStaffSign(
          fields.pinCode,
          fields.signDate,
          buttonName,

          // fields.signTime
          // mergeDateAndTimeInToUtc(fields.signDate, fields.signTime)
        );

      }
    }
    else {

      const firstErrorField = Object.keys(errors)[0];
      document.getElementsByName(firstErrorField)[0]?.focus();
    }
  };

  /* ============================= Render functions ============================= */
  return (
    <Dialog
      onClose={onClose}
      title={"Apply Signature"}
      className="small-dailog width_90 eyeIcons blue_theme" width={"100%"} height={"75%"}
    >
      {mainLoading && <Loading />}

      <div className="edit-client-popup ">
        {template?.canApplyClientSig && (
          <div className="mb-2 col-lg-12 col-md-12 col-12">
            <DropDownKendoRct
              ref={selectedTypeRef}
              data={filteredOptionData}
              onChange={handleChange}
              name="signType"
              value={(fields && fields.signType && fields.signType != null) && fields.signType}
              required={true}
              validityStyles={settingError}
              error={!fields.signType && errors && errors.signType}
              label="Select Type"
            />
          </div>
        )}

        <div className="col-lg-12 col-md-12 col-12" style={{ display: "flex" }}>
          <div className="mb-2 col-lg-12 col-md-12 col-6 custom-calender-align position-relative" style={{ paddingLeft: "0px" }}>
            <DatePickerKendoRct
              ref={dateOfSignatureRef}
              min={serviceDate}
              max={new Date(new Date().getTime() + (docSettings?.numDaysApplySigAfterDos || docSettings?.numDaysAllowedInPast as number) * 24 * 60 * 60 * 1000)}
              onChange={handleChange}
              value={(fields && fields != null && fields.signDate && fields.signDate != null) ? fields?.signDate : undefined}
              name={"signDate"}
              title={"Signature Date"}
              required={true}
              validityStyles={settingError}
              error={(!fields.signDate && errors && errors.signDate) && errors.signDate}
              label={"Signature Date"}
              placeholder={""}
              disabled={!isStaffDoc && !docSettings?.canChooseSigDate}
            />
            <img src={calenderIcon} className="blueThemeIcons" alt="calender" />
          </div>

          {/* <div className="mb-2 col-lg-6 col-md-6 col-6" style={{ paddingRight: "0px" }}>
            <TimePickerKendoRct
              steps={steps}
              ref={timeOfSignatureRef}
              validityStyles={settingError}
              onChange={handleChange}
              placeholder="Signature Time"
              name={"signTime"}
              label={"Signature Time"}
              value={(fields && fields.signTime && fields.signTime!=null) && fields.signTime}
              required={true}
              error={(!fields.signTime && errors && errors.signTime)&& errors.signTime}
              disabled={sigControlDisabled}
            />
          </div> */}
        </div>


        <div className="mb-2 col-lg-12 col-md-12 col-12 ">
          <InputKendoRctV1
            ref={signaturePinRef}
            value={(fields && fields != null && fields.pinCode && fields.pinCode != null) ? fields.pinCode : undefined}
            onChange={handleChange}
            name="pinCode"
            label="Signature Pin"
            error={(errors && errors.pinCode && !fields.pinCode || errors?.pinCode) ? errors.pinCode : ""}
            validityStyles={settingError}
            required={true}
            type={showPin}
            maxLength={4}
            minLength={4}
          //disabled={sigControlDisabled}
          />
          <div onClick={handleShowPin} className="cursor-pointer eye-close">
            {showPin !== "password" ? (
              <i className="far fa-eye text-theme"></i>
            ) : (
              <i className="far fa-eye-slash"></i>
            )}
          </div>
        </div>

        {(fields?.signType === "Staff Signature" || isstaffSignConsent) &&
          <div className="mb-2 col-lg-12 col-md-12 col-12">
            <Checkbox
              name="staffSignType"
              value={staffCheckbox}
              onChange={() => setStaffCheckbox(!staffCheckbox)}
              ref={checkboxRef}
            />
            <span style={{ color: '#231E2D' }}>I attest that this information is true, accurate, and complete to the best of my knowledge. I understand that any falsification, omission, or concealment of material fact may result in administrative, civil, or criminal liability.</span>
            {((fields?.signType === "Staff Signature" || isstaffSignConsent) && staffCheckbox === false) && <Error>{errors.staffSignType}</Error>}
          </div>
        }

      </div>


      <p className="error-message">{signText}</p>
      <div className="preview-btn-bottom ">
        <div className="border-bottom-line"></div>

        <div className="d-flex my-3 px-3">
          {staffInfo?.canReviewDocuments && (viewDoc && fields?.signType === "Staff Signature") &&
            <div>
              <button
                ref={applyApproveRef}
                name="apply&approve"
                onClick={(event) => handleSubmit(event, "apply&approve")}
                disabled={isButtonDisable || mainLoading}


                className="btn completed"
                style={{ backgroundColor: "green", color: "white", fontSize: "14px",  padding:"6px 12px" }}
              >
                {mainLoading ? "Applying, Please wait.." : "Apply & Approve"}
              </button>
            </div>
          }
          <div>
            <button
              ref={submitRef}
              onClick={handleSubmit}
              disabled={isButtonDisable || mainLoading}
              className="submitButon"
            >
              {mainLoading ? "Applying, Please wait.." : "Apply"}
            </button>
          </div>
          <div>
            <button className="cancelBtn mr-2" onClick={onClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default AddDocumentSignature;
