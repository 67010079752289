import React from "react";
import { Error } from "@progress/kendo-react-labels";
import { Input } from "@progress/kendo-react-inputs";

type InputKendoRctProps = {
  validityStyles?: boolean;
  name: string;
  value: string | number;
  onChange: (e: any) => void;
  label?: string;
  error?: string;
  minLength?: number;
  required?: boolean;
  maxLength?: number;
  type?: string;
  disabled?: boolean;
  focusout?: () => void;
  placeholder?: string;
  pattern?: string;
  width?: string | number;
  min?: number;
  format?: string;
};

const InputKendoRct: React.FC<InputKendoRctProps> = ({
  validityStyles = true,
  name,
  value,
  onChange,
  label,
  error,
  minLength,
  required = false,
  maxLength,
  type = "text",
  disabled = false,
  focusout,
  placeholder,
  pattern,
  width = "100%",
  min,
  format,
}) => {
  return (
    <div>
      <label>{label}</label>
      <Input
        style={{ width }}
        validityStyles={validityStyles}
        name={name}
        value={value}
        onChange={onChange}
        label={""}
        maxLength={maxLength}
        type={type}
        disabled={disabled}
        minLength={minLength}
        // focusout={focusout}
        required={required}
        placeholder={placeholder}
        pattern={pattern}
        width={width}
        min={min}
      // format={format}
      />
      {error && <Error>{error}</Error>}
    </div>
  );
};

export default InputKendoRct;
